import React from "react";

export const Backdrop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 1800 900"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        fill="currentColor"
        d="m356.11 508.14-1.67-2.97c-37.54-66.73-72.89-129.58-123.11-194.07-28.84-37.04-56.18-77.61-59.88-124.42-3.92-49.58 58.8-73.59 69.24-18.85 3.46 18.17 5.21 36.41 6.94 54.59 3.31 34.68 6.6 69.08 21.76 102.14 11.03 24.05 31.85 40.43 57.59 29.02 38.04-16.86 12.49-63-3.61-92.06-2.71-4.9-5.16-9.31-6.98-13.01-18.84-38.16-22.9-77.97-27.08-118.93-.42-4.15-.85-8.31-1.29-12.48-3.46-32.6-16.32-64.91-39.64-88.44-27.99-28.3-63.9-35.41-101.51-22.31-59 20.56-172.2 134.78-21.94 316.15 22.7 27.4 49.96 50.5 77.17 73.56 8.57 7.26 17.13 14.51 25.55 21.9 59.01 51.79 107.72 109.99 131.98 182.03 25.06 74.38 30.14 162.84-19.51 223.65-62.41 76.49-132 51.31-169.35 15.27-36.28-35.01-37.9-66.27-39.83-103.45-.8-15.39-1.65-31.8-5.04-49.9-6.27-33.52-22.93-64.73-43.97-91.56-14.93-19.05-39.91-36.37-61.81-26.08-21.12 9.93-24.39 40.05-14.77 61.31 6.31 13.94 16.37 25.56 26.45 37.2 5.29 6.1 10.58 12.22 15.34 18.67 10.57 14.33 32.29 50.28 27.49 100.22-4.3 44.67 3.8 92.82 25.58 132.59 67.31 122.95 230.27 113.42 292.22-79.64 50.56-157.61-6.05-276.32-36.35-330.16z"
      />
      <path
        fill="currentColor"
        d="m1211.84 132.53c.93-4 1.4-7.28 1.8-10.16 1.16-8.15 1.85-12.99 11.41-21.47 5.27-4.66 11.05-7.86 16.69-10.99 9.94-5.5 19.48-10.79 25.16-23.47 9.06-20.26-1.23-45.92-20.19-57.49-18.95-11.56-43.95-10.25-63.72-.16s-34.74 27.85-45.39 47.31c-8.58 15.67-14.75 33.02-14.93 50.88-.2 17.85 6.19 36.25 19.47 48.17v.02c26.76 24.06 61.99 10.38 69.7-22.64z"
      />
      <path
        fill="currentColor"
        d="m1305.24 184.78.02-.02c-26.06-11.29-54.74 9.91-71.59 32.78-36.54 49.62-55.77 108.14-55.66 169.85.16 71.98 26.43 139.36 52.24 205.59 3.2 8.2 6.38 16.38 9.52 24.55 26.63 69.49 25.97 144.53-4.42 213.24-7.48 16.89-18.72 31.38-29.95 45.84-11.83 15.24-23.64 30.45-30.99 48.41-10.37 25.34-5.25 60.53 19.95 71.28 26.12 11.15 55.06-9.97 71.36-33.22 70.16-99.94 26.8-284.6 7.99-338.46-68.99-197.31-10.8-277.77 27.63-330.9 10.01-13.84 18.68-25.83 23.41-37.54 10.27-25.38 5.61-60.49-19.51-71.4z"
      />
      <path
        fill="currentColor"
        d="m767.49 428.12c67.51-89.45 48.53-186.29 37.23-235.47l.02-.02c-42.34-183.95-169.45-203.95-226.84-186.02-140.03 43.79-153.48 191.82-128.72 289 37.92 148.81 161.74 138.62 204.29 112.07 15.14-9.45 25.62-22.38 34.04-32.76 10.54-13 17.86-22.02 27.05-15.31 22.75 16.62-24.86 69.19-32.99 77.93-15.1 16.19-30.23 31.37-45.09 46.29-75.05 75.32-143.2 143.71-165.94 299.64-8.04 55.14 5.34 122.26 32.19 171.18 8.26 15.05 34.7 59.08 71.59 41.12 42.92-20.9 23.61-71.76 15.98-87.62-1.4-2.91-3.93-7.42-7.17-13.2-14.42-25.71-42.77-76.29-46.74-119.77-14.29-156.62 75.11-221.86 151.69-277.75 31.24-22.81 60.35-44.05 79.41-69.31zm-150.98-263.18c-1.5 2.29-3 4.58-4.43 6.91v.02c-2.55 4.16-4.5 7.86-5.95 11.4-3.96 9.77-3.98 18.25-1.69 31.23 6.11 34.67-17.48 66.84-55.42 54.64-17.64-5.67-31.45-20.4-38.84-37.38s-8.88-36.03-7.41-54.5c1.85-22.93 8.5-46.1 22.95-64.01s37.59-29.76 60.4-26.81c22.83 2.95 43.46 22.87 43.46 45.88 0 12.69-6.5 22.59-13.07 32.62z"
      />
      <path
        fill="currentColor"
        d="m1707.2 573.24-.02-.02c-18.94-25.1-47.51-46.24-78.11-68.88-75.67-55.98-163.76-121.15-149.44-278.19 4.12-45.17 32.01-97.98 45.04-122.64 2.33-4.41 4.18-7.92 5.34-10.33 7.61-15.84 26.93-66.7-16-87.62-36.89-17.97-63.33 26.08-71.59 41.12-26.87 48.93-40.25 116.05-32.21 171.18 22.75 155.91 90.89 224.3 165.92 299.61 14.87 14.92 30 30.12 45.11 46.32 8.15 8.74 55.72 61.31 32.99 77.93-9.19 6.73-16.51-2.3-27.05-15.3-8.43-10.39-18.91-23.32-34.04-32.77-42.57-26.55-166.37-36.75-204.29 112.06-24.76 97.16-11.31 245.21 128.72 289 57.37 17.94 184.5-2.07 226.84-186.01 11.33-49.17 30.28-146-37.21-235.46zm-137.97 295.75c0 23.01-20.61 42.96-43.44 45.88-22.83 2.95-45.95-8.9-60.42-26.81-14.45-17.91-21.1-41.08-22.95-64.01-1.49-18.45 0-37.52 7.41-54.5 7.4-16.98 21.22-31.71 38.83-37.38 37.95-12.22 61.54 19.96 55.42 54.64-2.28 12.96-2.26 21.46 1.72 31.23 1.43 3.54 3.38 7.24 5.93 11.4 1.43 2.34 2.94 4.63 4.44 6.93h.01c6.57 10.02 13.06 19.92 13.05 32.6z"
      />
      <path
        fill="currentColor"
        d="m1090.25 508.88c-7.11-24.21-14.21-48.41-21.05-72.7-3.04-10.79-6.13-21.12-9.16-31.23-8.83-29.51-17.13-57.25-22.07-89.67h.02c-3.23-21.18-4.9-47.97-4.7-69.65.34-36.84-15.23-64.85-44.59-65.65-41.37-1.09-49.15 33.68-50.15 48.27-2.09 30.83 6.13 55.99 19.43 83.88 10.34 21.67 25.25 46.67 40.54 72.29 20.31 34.04 41.28 69.19 53.03 99.09 25.46 64.77 30.37 127.53 18.23 194.85-16.5 91.48-72.08 207.37-181.85 204.07-16.74-.52-35.1-7.13-48.04-17.34-21.56-17.02-37.9-47.16-43.1-74.23-7.96-41.42 3.29-82.6 14.35-123.13 4.52-16.54 9-32.97 12.14-49.27 7.52-38.99-3.94-104.58-57.87-100.8-35.1 2.45-54.53 42.16-59.96 76.91-9.89 63.22-8.54 127.41-.5 190.83 7.82 61.71 34.58 123.29 75.65 166.98 48.01 51.12 130.81 60.26 192.3 26.81 61.55-33.48 110.41-105.2 132.86-189.57 20.09-75.42 18.58-163.38-3.26-238.78-4.05-13.99-8.15-27.97-12.25-41.96z"
      />
    </svg>
  );
};
