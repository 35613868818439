import React from "react";
import { Button } from "@0xsquid/ui";

interface LoadOrdersButtonProps {
  hasMoreOrders: boolean;
  isLoading: boolean;
  onClick: () => void;
}

export function LoadOrdersButton({ hasMoreOrders, isLoading, onClick }: LoadOrdersButtonProps) {
  return (
    <>
      {hasMoreOrders && (
        <Button
          size="lg"
          variant="primary"
          onClick={onClick}
          disabled={isLoading}
          className="tw-max-w-44 tw-text-body-small tw-mx-auto"
        >
          {isLoading ? "Loading..." : "Load More"}
        </Button>
      )}
    </>
  );
}
