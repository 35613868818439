import React, { useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import { SquidLogo } from "./icons/SquidLogo";
import { HeadingText, Input } from "@0xsquid/ui";
import { Notification } from "./shared/Notification";

const TransactionSearch: React.FC = () => {
  const [hashInput, setHashInput] = useState("");
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
      inputRef.current?.focus();
    }
  };

  const pasteFromClipboard = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    if (text) {
      setHashInput(text);
    }
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-squid-xl tw-flex-1 tw-self-stretch tw-px-squid-xs tw-justify-center tw-h-full">
      <header className="tw-flex tw-h-[132px] tw-flex-col tw-justify-center tw-items-center tw-gap-squid-m">
        <SquidLogo className="tw-flex-shrink-0" />

        <HeadingText size="medium">Coralscan</HeadingText>
      </header>

      <Notification message={notificationMessage} visible={notificationVisible} />

      <form className="tw-max-w-[600px] tw-w-full" onSubmit={handleSearchSubmit}>
        <Input
          inputRef={inputRef}
          type="text"
          onChange={e => setHashInput(e.target.value)}
          placeholder="Search by tx hash, order hash or address"
          value={hashInput}
          actionButtonProps={{
            label: "Paste",
            onClick: pasteFromClipboard,
          }}
        />
      </form>
    </div>
  );
};

export default TransactionSearch;
