import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  chains,
  currentTime,
  getBlockExplorerUrl,
  getChainImage,
  getTokenImage,
  stepLabels,
} from "../utils/constants";
import {
  notificationStyle,
  showNotificationStyle,
  noOrdersFoundStyle,
  imageStyle,
  copyIconStyle,
  orderDataValueStyle,
} from "../styles/styles";
import {
  calculateFeeTotal,
  calculateFinalExchangeRate,
  calculateRawExchangeRate,
  calculateSourceTotal,
  formatElapsedTime,
  formatAddress,
  formatDate,
  formatTokenAmount,
  formatTxHash,
  calculateElapsedTime,
} from "../utils/formatting";
import { SquidRFQOrder } from "../types";
import { fetchOrder } from "../services/api";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import {
  BadgeImage,
  BodyText,
  Checkmark1Icon,
  Chip,
  HeadingText,
  Loader,
  PropertyListItem,
} from "@0xsquid/ui";
import { PageLayout } from "./shared/PageLayout";
import { OrdersPageHeader } from "./OrdersPageHeader";
import { IconCopy } from "@tabler/icons-react";
import { BlobImage } from "./shared/blob/BlobImage";
import { BlobConnector } from "./shared/blob/BlobConnector";
import { OrderInfoSection } from "./shared/OrderInfoSection";

export const Spinner = () => <Loader size="60" />;

const Notification = ({ message, visible }: { visible: boolean; message: string }) => (
  <div
    style={{
      ...notificationStyle,
      ...(visible ? showNotificationStyle : {}),
    }}
  >
    {message}
  </div>
);

const TransactionDetails: React.FC = () => {
  const { transactionHash } = useParams<{ transactionHash: string }>();
  const navigate = useNavigate();
  const [hashInput, setHashInput] = useState("");
  const [orderData, setOrderData] = useState<SquidRFQOrder | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const loadOrderData = async (hash: string) => {
      setLoading(true);
      try {
        const data = await fetchOrder(hash);
        setOrderData(data);
        setError(null);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError("Order not found");
        } else {
          setError("Failed to fetch order data.");
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (transactionHash) {
      loadOrderData(transactionHash);
    }
  }, [transactionHash]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
      inputRef.current?.focus();
    }
  };

  const elapsedTime = orderData
    ? orderData.orderFilledBlockTimestamp
      ? orderData.orderFilledBlockTimestamp - orderData.orderCreatedBlockTimestamp
      : currentTime - orderData.orderCreatedBlockTimestamp
    : null;

  const formattedElapsedTime = elapsedTime !== null ? formatElapsedTime(elapsedTime) : "N/A";

  const settlementTimestamps = [
    orderData?.settlementForwardedBlockTimestamp,
    orderData?.settlementProcessedBlockTimestamp,
    orderData?.settlementFilledBlockTimestamp,
    orderData?.tokensReleasedBlockTimestamp,
  ];

  const transactionHashes = [
    orderData?.settlementForwardedTxHash,
    orderData?.settlementProcessedTxHash,
    orderData?.settlementFilledTxHash,
    orderData?.tokensReleasedTxHash,
  ];

  const settlementChainKeys = [
    orderData?.toChain, // Settlement Forwarded
    "250", // Settlement Processed
    "250", // Settlement Filled
    orderData?.fromChain, // Tokens Released
  ];

  const settlementChainIds = [
    orderData?.toChain, // Settlement Forwarded
    "250", // Settlement Processed
    "250", // Settlement Filled
    orderData?.fromChain, // Tokens Released
  ];

  const settlementChainNames = settlementChainKeys.map(key => chains[key as string] || "unknown");

  const swapChainKeys = [orderData?.fromChain, orderData?.toChain];
  const swapChainNames = swapChainKeys.map(key => chains[key as string] || "unknown");

  const swapTokenSymbols = [
    orderData?.fromTokenSymbol.toLowerCase() || "",
    orderData?.toTokenSymbol.toLowerCase() || "",
  ];

  const copyToClipboard = (text: string, transactionType: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setNotificationMessage(`${transactionType} copied to clipboard`);
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
    });
  };

  const feeRate = orderData?.feeRate ?? "0";

  const rawExchangeRate =
    orderData && calculateRawExchangeRate(orderData.fromAmount!, orderData.fillAmount!);
  const finalExchangeRate =
    orderData && calculateFinalExchangeRate(orderData.fromAmount!, orderData.fillAmount!, feeRate);
  const sourceTotal = orderData && calculateSourceTotal(orderData.fromAmount!, feeRate).toString();
  const feeTotal = orderData && calculateFeeTotal(orderData.fromAmount!, feeRate);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <PageLayout>
      <OrdersPageHeader
        hashInput={hashInput}
        setHashInput={setHashInput}
        handleSearchSubmit={handleSearchSubmit}
      />

      <main
        ref={containerRef}
        className="tw-max-w-[1000px] tw-w-full tw-h-full tw-mx-auto tw-text-grey-300 tw-flex tw-flex-col tw-items-center tw-gap-squid-xxs"
      >
        <header className="tw-flex tw-w-full tw-py-squid-m tw-px-squid-s mobile-lg:tw-px-squid-xl tw-flex-col tw-justify-center tw-items-start tw-gap-squid-xs tw-self-stretch">
          <BodyText size="small">Transaction</BodyText>

          <HeadingText size="small">{formatAddress(transactionHash)}</HeadingText>
        </header>

        <>
          {loading ? (
            <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
              <Spinner />
            </div>
          ) : error === "Order not found" ? (
            <div style={noOrdersFoundStyle}>Order not found</div>
          ) : (
            orderData != null && (
              <>
                {/* Swap Status */}
                <OrderInfoSection>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  <Notification message={notificationMessage} visible={notificationVisible} />

                  <BodyText size="medium">Swap Status</BodyText>
                  {orderData ? (
                    <>
                      <div className="tw-flex tw-pt-squid-m tw-justify-between tw-items-center tw-self-stretch">
                        {/* Source step */}
                        <BlobImage
                          subtitle={
                            orderData?.orderCreatedTxHash ? (
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.fromChain!,
                                  orderData.orderCreatedTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Created
                              </a>
                            ) : (
                              "Pending Created"
                            )
                          }
                          direction="left"
                        >
                          <BadgeImage
                            image={{
                              src: getTokenImage(swapTokenSymbols[0]),
                              rounded: "full",
                              alt: "Source chain",
                              size: "xxlarge",
                            }}
                            badge={{
                              src: getChainImage(swapChainNames[0]),
                              alt: "Created",
                              size: "medium",
                              border: "outline-lg",
                              className: "!tw-outline-[--st-color-royal-800]",
                            }}
                          />
                        </BlobImage>

                        {/* Refund step */}
                        {orderData?.orderRefundedTxHash ? (
                          <>
                            <BlobConnector stage="complete" />

                            <BlobImage
                              subtitle={
                                orderData?.orderRefundedTxHash ? (
                                  <a
                                    href={getBlockExplorerUrl(
                                      orderData.fromChain!,
                                      orderData.orderRefundedTxHash,
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Refunded
                                  </a>
                                ) : null
                              }
                              direction="middle"
                            >
                              <BadgeImage
                                image={{
                                  src: getTokenImage(swapTokenSymbols[0]),
                                  rounded: "full",
                                  alt: swapTokenSymbols[0],
                                  size: "xxlarge",
                                }}
                                badge={{
                                  src: getChainImage(swapChainNames[0]),
                                  alt: swapChainNames[0],
                                  size: "medium",
                                  border: "outline-lg",
                                  className: "!tw-outline-[--st-color-royal-800]",
                                }}
                              />
                            </BlobImage>

                            <BlobConnector stage="pending" />
                          </>
                        ) : (
                          <BlobConnector
                            stage={!!orderData?.orderFilledBlockTimestamp ? "complete" : "started"}
                          />
                        )}

                        {/* Destination step */}
                        <BlobImage
                          subtitle={
                            orderData?.orderFilledTxHash ? (
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.toChain!,
                                  orderData.orderFilledTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Filled
                              </a>
                            ) : orderData?.orderRefundedTxHash ? null : (
                              "Pending Fill"
                            )
                          }
                          direction="right"
                        >
                          <BadgeImage
                            image={{
                              src: getTokenImage(swapTokenSymbols[1]),
                              alt: swapTokenSymbols[1],
                              rounded: "full",
                              size: "xxlarge",
                            }}
                            badge={{
                              src: getChainImage(swapChainNames[1]),
                              alt: swapChainNames[1],
                              size: "medium",
                              border: "outline-lg",
                              className: "!tw-outline-[--st-color-royal-800]",
                            }}
                          />
                        </BlobImage>
                      </div>

                      <div className="tw-flex tw-py-0 tw-px-squid-xs tw-justify-center tw-items-center tw-self-stretch tw-text-grey-300">
                        {!orderData?.orderRefundedTxHash && (
                          <div className="tw-flex tw-h-squid-l tw-py-0 tw-pl-squid-xs tw-pr-squid-xxs tw-rounded-squid-s tw-bg-status-positive tw-text-grey-900 tw-justify-center tw-items-center">
                            <Checkmark1Icon size="16" />
                            <BodyText size="small">
                              {orderData?.orderFilledBlockTimestamp
                                ? `Filled in ${formattedElapsedTime}`
                                : `Filling... ${formattedElapsedTime}`}
                            </BodyText>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
                      <Spinner />
                    </div>
                  )}
                </OrderInfoSection>

                {/* Settlement Status */}
                {!orderData?.orderRefundedTxHash && (
                  <OrderInfoSection>
                    <BodyText size="medium">Settlement Status</BodyText>
                    <div className="tw-flex tw-pt-squid-m tw-pb-squid-xl tw-justify-between tw-items-center tw-self-stretch">
                      {settlementTimestamps.map((timestamp, index, self) => (
                        <React.Fragment key={index}>
                          {index > 0 && (
                            <div className="tw-relative tw-flex tw-w-full tw-justify-center tw-items-center">
                              <BlobConnector stage={!!timestamp ? "complete" : "pending"} />
                              <span className="tw-flex tw-py-squid-xxs tw-px-0 tw-items-center tw-absolute -tw-bottom-squid-xxl">
                                <Chip label={calculateElapsedTime(self[index - 1], timestamp)} />
                              </span>
                            </div>
                          )}
                          <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-relative">
                            <BlobImage
                              isPending={!timestamp}
                              subtitle={
                                <>
                                  {timestamp ? (
                                    transactionHashes[index] ? (
                                      <a
                                        href={getBlockExplorerUrl(
                                          settlementChainIds[index]!,
                                          transactionHashes[index]!,
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {stepLabels[index]}
                                      </a>
                                    ) : (
                                      <span>Pending {stepLabels[index]}</span>
                                    )
                                  ) : (
                                    <span>Pending {stepLabels[index]}</span>
                                  )}
                                </>
                              }
                              direction={
                                index === 0
                                  ? "left"
                                  : index === self.length - 1
                                    ? "right"
                                    : "middle"
                              }
                            >
                              <BadgeImage
                                image={{
                                  src: getChainImage(settlementChainNames[index]),
                                  alt: stepLabels[index],
                                  size: "xxlarge",
                                  rounded: "full",
                                }}
                                badge={{
                                  src: getChainImage(settlementChainNames[index]),
                                  alt: stepLabels[index],
                                  size: "medium",
                                  rounded: "xxs",
                                  border: "outline-lg",
                                  className: "!tw-outline-[--st-color-royal-800]",
                                }}
                              />
                            </BlobImage>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </OrderInfoSection>
                )}

                {/* Order Details */}
                <OrderInfoSection>
                  <div className="tw-flex tw-px-squid-m tw-justify-center tw-items-center tw-gap-squid-xs tw-text-grey-300">
                    <BodyText size="medium">Details</BodyText>
                  </div>

                  <ul className="tw-flex tw-py-squid-m tw-flex-col tw-items-start tw-self-stretch tw-overflow-auto">
                    {/* Order Data */}
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Source Chain"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getChainImage(chains[orderData.fromChain || "unknown"])}
                            alt="Source Chain"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          {chains[orderData.fromChain || "unknown"].charAt(0).toUpperCase() +
                            chains[orderData.fromChain || "unknown"].slice(1)}
                          <span style={{ marginLeft: "8px" }}>
                            {orderData.fromChain || "unknown"}
                          </span>
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Destination Chain"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getChainImage(chains[orderData.toChain || "unknown"])}
                            alt="Destination Chain"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          {chains[orderData.toChain || "unknown"].charAt(0).toUpperCase() +
                            chains[orderData.toChain || "unknown"].slice(1)}
                          <span style={{ marginLeft: "8px" }}>{orderData.toChain || ""}</span>
                        </div>
                      }
                    ></PropertyListItem>

                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Source Address"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getChainImage(chains[orderData.fromChain || ""])}
                            alt="Source Address"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          <span style={{ marginLeft: "8px" }}>
                            {formatAddress(orderData.fromAddress || "")}
                          </span>
                          <IconCopy
                            width={16}
                            height={16}
                            style={{ ...copyIconStyle, cursor: "pointer" }}
                            onClick={() => copyToClipboard(orderData.fromAddress!, "From Address")}
                          />
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Destination Address"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getChainImage(chains[orderData.toChain || ""])}
                            alt="Destination Address"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          <span style={{ marginLeft: "8px" }}>
                            {formatAddress(orderData.toAddress || "")}
                          </span>
                          <IconCopy
                            width={16}
                            height={16}
                            style={{ ...copyIconStyle, cursor: "pointer" }}
                            onClick={() => copyToClipboard(orderData.toAddress!, "To Address")}
                          />
                        </div>
                      }
                    ></PropertyListItem>

                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Source Asset"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getTokenImage(orderData.fromTokenSymbol?.toLowerCase() || "")}
                            alt="Source Asset"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          {formatTokenAmount(
                            orderData.fromAmount || "0",
                            (orderData.fromTokenDecimals !== undefined
                              ? orderData.fromTokenDecimals
                              : orderData.fromTokenSymbol === "USDC"
                                ? "6"
                                : "18"
                            ).toString(),
                          )}{" "}
                          {orderData.fromTokenSymbol}
                          <span style={{ marginLeft: "8px" }}>
                            {formatAddress(orderData.fromToken || "")}
                          </span>
                          <IconCopy
                            width={16}
                            height={16}
                            style={{ ...copyIconStyle, cursor: "pointer" }}
                            onClick={() =>
                              copyToClipboard(orderData.fromToken!, "From Token Address")
                            }
                          />
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Destination Asset"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getTokenImage(orderData.toTokenSymbol?.toLowerCase() || "")}
                            alt="Destination Asset"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          {formatTokenAmount(
                            orderData.fillAmount || "0",
                            (orderData.toTokenDecimals !== undefined
                              ? orderData.toTokenDecimals
                              : orderData.toTokenSymbol === "USDC"
                                ? "6"
                                : "18"
                            ).toString(),
                          )}{" "}
                          {orderData.toTokenSymbol}
                          <span style={{ marginLeft: "8px" }}>
                            {formatAddress(orderData.toToken || "")}
                          </span>
                          <IconCopy
                            width={16}
                            height={16}
                            style={{ ...copyIconStyle, cursor: "pointer" }}
                            onClick={() => copyToClipboard(orderData.toToken!, "To Token Address")}
                          />
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Fee Rate"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {orderData.feeRate}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      tooltip={{
                        containerRef,
                        tooltipWidth: "max",
                        showOnMobile: true,
                        containerClassName: "tw-flex tw-items-center tw-h-fit",
                        tooltipContent: (
                          <>
                            <strong>Raw Exchange Rate:</strong>
                            <br />
                            fillAmount / fromAmount
                          </>
                        ),
                      }}
                      label="Raw Exchange Rate"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {rawExchangeRate.toFixed(6)}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      tooltip={{
                        containerRef,
                        tooltipWidth: "max",
                        showOnMobile: true,
                        containerClassName: "tw-flex tw-items-center tw-h-fit",
                        tooltipContent: (
                          <>
                            <strong>Final Exchange Rate:</strong>
                            <br />
                            fillAmount / (fromAmount - ((fromAmount * feeRate / 1000000)))
                          </>
                        ),
                      }}
                      label={"Final Exchange Rate"}
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {finalExchangeRate.toFixed(6)}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      tooltip={{
                        containerRef,
                        tooltipWidth: "max",
                        showOnMobile: true,
                        containerClassName: "tw-flex tw-items-center tw-h-fit",
                        tooltipContent: (
                          <>
                            <strong>Source Total:</strong>
                            <br />
                            fromAmount - ((fromAmount * feeRate) / 1000000)
                          </>
                        ),
                      }}
                      label="Source Total"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getTokenImage(orderData.fromTokenSymbol?.toLowerCase() || "")}
                            alt="Source Total"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          {formatTokenAmount(
                            sourceTotal || "0",
                            (orderData.fromTokenDecimals !== undefined
                              ? orderData.fromTokenDecimals
                              : orderData.fromTokenSymbol === "USDC"
                                ? "6"
                                : "18"
                            ).toString(),
                          )}{" "}
                          {orderData.fromTokenSymbol}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      tooltip={{
                        containerRef,
                        tooltipWidth: "max",
                        showOnMobile: true,
                        containerClassName: "tw-flex tw-items-center tw-h-fit",
                        tooltipContent: (
                          <>
                            <strong>Fee Total:</strong>
                            <br />
                            (fromAmount * feeRate) / 1000000
                          </>
                        ),
                      }}
                      label="Fee Total"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          <img
                            src={getTokenImage(orderData.fromTokenSymbol?.toLowerCase() || "")}
                            alt="Fee Total"
                            style={{ ...imageStyle, width: 24, height: 24 }}
                          />
                          {formatTokenAmount(
                            feeTotal || "0",
                            (orderData.fromTokenDecimals !== undefined
                              ? orderData.fromTokenDecimals
                              : orderData.fromTokenSymbol === "USDC"
                                ? "6"
                                : "18"
                            ).toString(),
                          )}{" "}
                          {orderData.fromTokenSymbol}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Source Amount USD"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          $
                          {parseFloat(orderData.fromAmountUSD).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Destination Amount USD"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          $
                          {parseFloat(orderData.fillAmountUSD).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Created"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.orderCreatedBlockTimestamp)}
                          {orderData.orderCreatedTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.orderCreatedTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.orderCreatedTxHash!,
                                    "Order Created transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    orderData.fromChain!,
                                    orderData.orderCreatedTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains[orderData.fromChain || ""])}
                                alt="Source Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Refunded"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.orderRefundedBlockTimestamp)}
                          {orderData.orderRefundedTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.orderRefundedTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.orderRefundedTxHash!,
                                    "Order Refunded transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    orderData.fromChain!,
                                    orderData.orderRefundedTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains[orderData.fromChain || ""])}
                                alt="Source Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Filled"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.orderFilledBlockTimestamp)}
                          {orderData.orderFilledTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.orderFilledTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.orderFilledTxHash!,
                                    "Order Filled transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    orderData.toChain!,
                                    orderData.orderFilledTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains[orderData.toChain || ""])}
                                alt="Destination Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Settlement Forwarded"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.settlementForwardedBlockTimestamp)}
                          {orderData.settlementForwardedTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.settlementForwardedTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.settlementForwardedTxHash!,
                                    "Settlement Forwarded transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    orderData.toChain!,
                                    orderData.settlementForwardedTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains[orderData.toChain || ""])}
                                alt="Destination Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Settlement Processed"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.settlementProcessedBlockTimestamp)}
                          {orderData.settlementProcessedTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.settlementProcessedTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.settlementProcessedTxHash!,
                                    "Settlement Processed transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    "250",
                                    orderData.settlementProcessedTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains["250"])}
                                alt="Hub Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Settlement Filled"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.settlementFilledBlockTimestamp)}
                          {orderData.settlementFilledTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.settlementFilledTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.settlementFilledTxHash!,
                                    "Settlement Filled transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    "250",
                                    orderData.settlementFilledTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains["250"])}
                                alt="Hub Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                    <PropertyListItem
                      iconClassName="tw-hidden"
                      labelClassName="!tw-min-w-[200px]"
                      label="Tokens Released"
                      variant="large"
                      detail={
                        <div className="tw-flex-shrink-0" style={orderDataValueStyle}>
                          {formatDate(orderData.tokensReleasedBlockTimestamp)}
                          {orderData.tokensReleasedTxHash && (
                            <>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTxHash(orderData.tokensReleasedTxHash)}
                              </span>
                              <IconCopy
                                width={16}
                                height={16}
                                style={{ ...copyIconStyle, cursor: "pointer" }}
                                onClick={() =>
                                  copyToClipboard(
                                    orderData.tokensReleasedTxHash!,
                                    "Tokens Released transaction hash",
                                  )
                                }
                              />
                              <span style={{ marginLeft: "8px" }}>
                                <a
                                  href={getBlockExplorerUrl(
                                    orderData.fromChain!,
                                    orderData.tokensReleasedTxHash,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View on Block Explorer
                                </a>
                              </span>
                              <img
                                src={getChainImage(chains[orderData.fromChain || ""])}
                                alt="Source Chain"
                                style={{ ...imageStyle, width: 24, height: 24 }}
                              />
                            </>
                          )}
                        </div>
                      }
                    ></PropertyListItem>
                  </ul>
                </OrderInfoSection>
              </>
            )
          )}
        </>
      </main>
    </PageLayout>
  );
};

export default TransactionDetails;
