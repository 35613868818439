import { BodyText, Image } from "@0xsquid/ui";
import React from "react";
import { chains, getChainImage, getTokenImage } from "src/utils/constants";
import { formatTokenAmount } from "src/utils/formatting";

interface TransferProps {
  chain: string;
  tokenSymbol: string;
  tokenDecimals: string;
  amount: string;
}

export function TransferDetails({ amount, chain, tokenSymbol, tokenDecimals }: TransferProps) {
  return (
    <div className="tw-flex tw-items-center tw-gap-squid-xxs tw-flex-1 tw-self-stretch tw-text-grey-300">
      <Image
        src={getChainImage(chains[chain || "unknown"])}
        rounded="xxs"
        size="medium"
        alt={chain}
      />

      <BodyText size="small" className="tw-text-grey-300 tw-capitalize">
        {chains[chain || "unknown"]}
      </BodyText>

      <Image
        src={getTokenImage(tokenSymbol.toLowerCase())}
        rounded="full"
        size="medium"
        alt={tokenSymbol}
      />

      <BodyText
        size="small"
        className="tw-text-grey-300 tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
      >
        {formatTokenAmount(
          amount || "0",
          (tokenDecimals !== undefined
            ? tokenDecimals
            : tokenSymbol === "USDC"
              ? "6"
              : "18"
          ).toString(),
        )}{" "}
        {tokenSymbol}
      </BodyText>
    </div>
  );
}
