import { cn } from "@0xsquid/ui";
import React from "react";

type BlobConnectorStage = "started" | "pending" | "complete";
const connectorClassMap: Partial<Record<BlobConnectorStage, string>> = {
  complete: "tw-flex-1",
  started: "tw-w-3",
};

const connectorContainerClassMap: Partial<Record<BlobConnectorStage, string>> = {
  complete: "tw-justify-center",
  started: "tw-justify-start",
  pending: "tw-justify-center",
};

interface BlobConnectorProps {
  stage: BlobConnectorStage;
}

export function BlobConnector({ stage }: BlobConnectorProps) {
  return (
    <div
      style={{
        backgroundColor: "var(--st-color-royal-800)",
        minWidth: "100px",
      }}
      className={cn("tw-flex tw-h-6 tw-items-center tw-flex-1", connectorContainerClassMap[stage])}
    >
      {stage != "pending" && (
        <span
          className={cn(
            "tw-h-3 tw-border-y tw-border-y-material-light-thin tw-bg-royal-500 tw-rounded-xl",
            connectorClassMap[stage],
          )}
        />
      )}
    </div>
  );
}
