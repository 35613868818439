import React, { useEffect, useState, useRef } from "react";
import { ethers, BrowserProvider, encodeBytes32String } from "ethers";
import { ExtendedRFQEventData, FilterState, SortConfig, StatusBoolean } from "../types";
import { columnHeaders, getAxelarUrl, getBlockExplorerUrl, spokeAddress } from "../utils/constants";
import { convertUnixToDate, formatDateForFilename } from "../utils/formatting";
import {
  createHubContractInstance,
  createSpokeContractInstance,
  createTokenContractInstance,
  determineEligibilityForRefund,
  enrichOrderData,
} from "../utils/eventHelpers";
import {
  activePageLinkStyle,
  analyticsContainerStyle,
  approveButtonStyle,
  batchChainIdInputStyle,
  batchControlContainerStyle,
  batchFillerAddressInputStyle,
  batchGasLimitInputStyle,
  batchInfoContainerStyle,
  batchValueInputStyle,
  buttonStyle,
  callsButtonStyle,
  callsContentStyle,
  callsDetailsStyle,
  callsInnerLabelStyle,
  callsLabelStyle,
  callsModalBackgroundStyle,
  callsModalContentStyle,
  callsPreStyle,
  closeCallsButtonStyle,
  connectButtonStyle,
  containerStyle,
  controlContainerStyle,
  dateRangeStyle,
  disconnectButtonStyle,
  eligibleFillStatusStyle,
  eligibleStatusStyle,
  evenRowStyle,
  expiryColumnStyle,
  exportButtonStyle,
  fillButtonStyle,
  filledStatusStyle,
  filterContainerStyle,
  headerStyle,
  infoStyle,
  inputStyle,
  mainContainerStyle,
  oddRowStyle,
  pageLinkStyle,
  paginationStyle,
  refundButtonStyle,
  resetButtonStyle,
  scrollableCallsContentStyle,
  selectedRowStyle,
  selectStyle,
  tableStyle,
  tableWrapperStyle,
  tdStyle,
  textLinkStyle,
  thStyle,
  trueStatusStyle,
  copyButtonContainerStyle,
  copyButtonStyle,
  notificationStyle,
  showNotificationStyle,
} from "../styles/styles";
import ButtonFeedback from "./ButtonFeedback";
import FilterInput from "./FilterInput";
import { hubChainId } from "../utils/constants";
import Analytics from "./Analytics";
import { isTokenSupported } from "../utils/validation";
import { fetchDashboardData, fetchPostHooks } from "../services/api";
import useWallet from "../hooks/useWallet";
import copy from "copy-to-clipboard";
import { Input } from "@0xsquid/ui";

const currentTimestamp = Date.now() / 1000;

const RFQ: React.FC = () => {
  const [rfqEvents, setRfqEvents] = useState<ExtendedRFQEventData[]>([]);
  const [showCopyButtonFor, setShowCopyButtonFor] = useState<string | null>(null);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [tokens, setTokens] = useState<any[]>([]);
  const { walletAddress, chainId, networkName, connectWallet, disconnectWallet } = useWallet();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [hideRefunded, setHideRefunded] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "blocktimestamp",
    direction: "descending",
  });
  const [hoverColumn, setHoverColumn] = useState<string | null>(null);
  const [selectedOrders, setSelectedOrders] = useState<ExtendedRFQEventData[]>([]);
  const [ethValue, setEthValue] = useState<string>("");
  const [gasLimit, setGasLimit] = useState<string>("");
  const [fillerAddress, setFillerAddress] = useState<string>("");
  const [customChainId, setCustomChainId] = useState<string>("");
  const [columnsOrder, setColumnsOrder] = useState(columnHeaders);
  const [visibleColumns, setVisibleColumns] = useState(
    columnHeaders.reduce((acc, header) => {
      acc[header] = true;
      return acc;
    }, {}),
  );
  const [dateRange, setDateRange] = useState("All time");
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [callsModalVisible, setCallsModalVisible] = useState(false);
  const [callsModalData, setCallsModalData] = useState<any>(null);
  const [callsModalOrderHash, setCallsModalOrderHash] = useState<string | null>(null);
  const [callsModalPostHookHash, setCallsModalPostHookHash] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const exportDropdownRef = useRef<HTMLDivElement>(null);

  const handleRowHover = (orderHash?: string) => {
    setShowCopyButtonFor(orderHash || null);
  };
  const displayNotification = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 2000);
  };
  const unixToDate = (unixTimestamp: string | null) => {
    if (!unixTimestamp) return null;
    const date = new Date(Number(unixTimestamp) * 1000);
    return date.toLocaleString();
  };
  const copyJson = (eventGroup: ExtendedRFQEventData) => {
    const {
      orderCreated,
      statuses,
      transactionHashes,
      blockTimestamps,
      usdValueFromToken,
      usdValueToToken,
      feeValue,
    } = eventGroup;
    const dataToCopy = {
      orderHash: orderCreated?.orderHash,
      orderDetails: {
        fromAddress: orderCreated?.data.order_fromAddress,
        toAddress: orderCreated?.data.order_toAddress,
        fromToken: orderCreated?.data.order_fromToken,
        toToken: orderCreated?.data.order_toToken,
        fromChain: orderCreated?.data.order_fromChain,
        toChain: orderCreated?.data.order_toChain,
        fromAmount: orderCreated?.data.order_fromAmount,
        fillAmount: orderCreated?.data.order_fillAmount,
        feeRate: orderCreated?.data.order_feeRate,
        postHookHash: orderCreated?.data.order_postHookHash,
        usdValueFromToken,
        usdValueToToken,
        feeValue,
      },
      transactionDetails: {
        OrderCreated: {
          status: true,
          transactionHash: transactionHashes.OrderCreated,
          blockTimestamp: blockTimestamps.OrderCreated,
          blockExplorerUrl: getBlockExplorerUrl(
            orderCreated?.data.order_fromChain,
            transactionHashes.OrderCreated,
          ),
          creationTime: unixToDate(blockTimestamps.OrderCreated),
        },
        OrderFilled: {
          status: statuses.OrderFilled,
          transactionHash: transactionHashes.OrderFilled,
          blockTimestamp: blockTimestamps.OrderFilled,
          blockExplorerUrl: transactionHashes.OrderFilled
            ? getBlockExplorerUrl(orderCreated?.data.order_toChain, transactionHashes.OrderFilled)
            : null,
          fillTime: blockTimestamps.OrderFilled ? unixToDate(blockTimestamps.OrderFilled) : null,
        },
        OrderRefunded: {
          status: statuses.OrderRefunded,
          transactionHash: transactionHashes.OrderRefunded,
          blockTimestamp: blockTimestamps.OrderRefunded,
          blockExplorerUrl: transactionHashes.OrderRefunded
            ? getBlockExplorerUrl(
                orderCreated?.data.order_fromChain,
                transactionHashes.OrderRefunded,
              )
            : null,
          refundTime: blockTimestamps.OrderRefunded
            ? unixToDate(blockTimestamps.OrderRefunded)
            : null,
        },
        SettlementForwarded: {
          status: statuses.SettlementForwarded,
          transactionHash: transactionHashes.SettlementForwarded,
          blockTimestamp: blockTimestamps.SettlementForwarded,
          blockExplorerUrl: transactionHashes.SettlementForwarded
            ? getBlockExplorerUrl(
                orderCreated?.data.order_toChain,
                transactionHashes.SettlementForwarded,
              )
            : null,
          forwardTime: blockTimestamps.SettlementForwarded
            ? unixToDate(blockTimestamps.SettlementForwarded)
            : null,
        },
        TokensReleased: {
          status: statuses.TokensReleased,
          transactionHash: transactionHashes.TokensReleased,
          blockTimestamp: blockTimestamps.TokensReleased,
          blockExplorerUrl: transactionHashes.TokensReleased
            ? getBlockExplorerUrl(
                orderCreated?.data.order_fromChain,
                transactionHashes.TokensReleased,
              )
            : null,
          releaseTime: blockTimestamps.TokensReleased
            ? unixToDate(blockTimestamps.TokensReleased)
            : null,
        },
        SettlementFilled: {
          status: statuses.SettlementFilled,
          transactionHash: transactionHashes.SettlementFilled,
          blockTimestamp: blockTimestamps.SettlementFilled,
          blockExplorerUrl: transactionHashes.SettlementFilled
            ? getBlockExplorerUrl(hubChainId, transactionHashes.SettlementFilled)
            : null,
          settleTime: blockTimestamps.SettlementFilled
            ? unixToDate(blockTimestamps.SettlementFilled)
            : null,
        },
        SettlementProcessed: {
          status: statuses.SettlementProcessed,
          transactionHash: transactionHashes.SettlementProcessed,
          blockTimestamp: blockTimestamps.SettlementProcessed,
          blockExplorerUrl: transactionHashes.SettlementProcessed
            ? getBlockExplorerUrl(hubChainId, transactionHashes.SettlementProcessed)
            : null,
          processTime: blockTimestamps.SettlementProcessed
            ? unixToDate(blockTimestamps.SettlementProcessed)
            : null,
        },
      },
    };
    copy(JSON.stringify(dataToCopy, null, 2));
    displayNotification();
  };

  const refreshRfqEvents = async () => {
    setRfqEvents([]);
    try {
      const { events } = await fetchDashboardData(true);
      console.log("Fetched events:", events);

      const enrichedData = events
        .filter(event => {
          const fromToken = event.orderCreated?.data.order_fromToken;
          const toToken = event.orderCreated?.data.order_toToken;

          return isTokenSupported(tokens, fromToken) && isTokenSupported(tokens, toToken);
        })
        .map(event => enrichOrderData(tokens, event));

      setRfqEvents(enrichedData);
    } catch (error) {
      console.error("Error refreshing RFQ events:", error);
    }
  };

  const transactionHashOptions = [
    ...new Set(
      rfqEvents.flatMap(event => Object.values(event.transactionHashes)).filter(hash => !!hash),
    ),
  ];

  const handleExportToCSV = () => {
    const visibleHeaders = columnsOrder.filter(header => visibleColumns[header]);

    const csvHeaders = visibleHeaders
      .map(header => {
        if (header === "Type") {
          return "Order Created";
        }
        return header;
      })
      .join(",");

    const csvRows = sortedEvents.map(event => {
      return visibleHeaders
        .map(header => {
          switch (header) {
            case "Type":
              return `true - ${getBlockExplorerUrl(
                event.orderCreated?.data.order_fromChain,
                event.transactionHashes.OrderCreated,
              )}`;
            case "Order Hash":
              return event.orderCreated?.orderHash || "";
            case "Order Refunded":
              return event.statuses.OrderRefunded
                ? `true - ${getBlockExplorerUrl(
                    event.orderCreated?.data.order_fromChain,
                    event.transactionHashes.OrderRefunded,
                  )}`
                : "false";
            case "Order Filled":
              return event.statuses.OrderFilled
                ? `true - ${getBlockExplorerUrl(
                    event.orderCreated?.data.order_toChain,
                    event.transactionHashes.OrderFilled,
                  )}`
                : "false";
            case "Settlement Forwarded":
              return event.statuses.SettlementForwarded
                ? `true - ${getBlockExplorerUrl(
                    event.orderCreated?.data.order_toChain,
                    event.transactionHashes.SettlementForwarded,
                  )}`
                : "false";
            case "Settlement Processed":
              return event.statuses.SettlementProcessed
                ? `true - ${getBlockExplorerUrl(
                    hubChainId,
                    event.transactionHashes.SettlementProcessed,
                  )}`
                : "false";
            case "Settlement Filled":
              return event.statuses.SettlementFilled
                ? `true - ${getBlockExplorerUrl(
                    hubChainId,
                    event.transactionHashes.SettlementFilled,
                  )}`
                : "false";
            case "Tokens Released":
              return event.statuses.TokensReleased
                ? `true - ${getBlockExplorerUrl(
                    event.orderCreated?.data.order_fromChain,
                    event.transactionHashes.TokensReleased,
                  )}`
                : "false";
            case "From Address":
              return event.orderCreated?.data.order_fromAddress || "";
            case "To Address":
              return event.orderCreated?.data.order_toAddress || "";
            case "Filler":
              return event.orderCreated?.data.order_filler || "";
            case "From Token":
              return event.orderCreated?.data.order_fromToken || "";
            case "To Token":
              return event.orderCreated?.data.order_toToken || "";
            case "Expiry":
              return `"${event.orderCreated?.data.order_expiry || ""} (${convertUnixToDate(
                event.orderCreated?.data.order_expiry || "",
              )})"`;
            case "From Amount":
              return event.orderCreated?.data.order_fromAmount || "";
            case "Fill Amount":
              return event.orderCreated?.data.order_fillAmount || "";
            case "Fee Rate":
              return event.orderCreated?.data.order_feeRate || "";
            case "From Chain":
              return event.orderCreated?.data.order_fromChain || "";
            case "To Chain":
              return event.orderCreated?.data.order_toChain || "";
            case "From Token USD":
              return event.usdValueFromToken || "";
            case "To Token USD":
              return event.usdValueToToken || "";
            case "Fee Value":
              return event.feeValue || "";
            case "Post Hook Hash":
              return event.orderCreated?.data.order_postHookHash || "";
            default:
              return "";
          }
        })
        .join(",");
    });

    const csvContent = [csvHeaders, ...csvRows].join("\n");

    const formattedDate = formatDateForFilename();
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `rfq_data_export_${formattedDate}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportToJSON = async () => {
    const visibleHeaders = columnsOrder.filter(header => visibleColumns[header]);

    const jsonData = await Promise.all(
      sortedEvents.map(async event => {
        const eventData = {};
        const postHookHash = event.orderCreated?.data.order_postHookHash || "";

        let callsData = null;
        if (postHookHash !== encodeBytes32String("")) {
          try {
            const postHooks = await fetchPostHooks([event.orderCreated?.orderHash]);
            callsData = postHooks.calls || null;
          } catch (error) {
            console.error(
              `Error fetching postHook data for ${event.orderCreated?.orderHash}:`,
              error,
            );
          }
        }

        visibleHeaders.forEach(header => {
          switch (header) {
            case "Type":
              eventData["Order Created"] = {
                status: true,
                link: getBlockExplorerUrl(
                  event.orderCreated?.data.order_fromChain,
                  event.transactionHashes.OrderCreated,
                ),
              };
              break;
            case "Order Hash":
              eventData["Order Hash"] = event.orderCreated?.orderHash || "";
              break;
            case "Order Refunded":
              eventData["Order Refunded"] = {
                status: event.statuses.OrderRefunded,
                link: event.statuses.OrderRefunded
                  ? getBlockExplorerUrl(
                      event.orderCreated?.data.order_fromChain,
                      event.transactionHashes.OrderRefunded,
                    )
                  : null,
              };
              break;
            case "Order Filled":
              eventData["Order Filled"] = {
                status: event.statuses.OrderFilled,
                link: event.statuses.OrderFilled
                  ? getBlockExplorerUrl(
                      event.orderCreated?.data.order_toChain,
                      event.transactionHashes.OrderFilled,
                    )
                  : null,
              };
              break;
            case "Settlement Forwarded":
              eventData["Settlement Forwarded"] = {
                status: event.statuses.SettlementForwarded || false,
                link: event.statuses.SettlementForwarded
                  ? getBlockExplorerUrl(
                      event.orderCreated?.data.order_toChain,
                      event.transactionHashes.SettlementForwarded,
                    )
                  : null,
              };
              break;
            case "Settlement Processed":
              eventData["Settlement Processed"] = {
                status: event.statuses.SettlementProcessed || false,
                link: event.statuses.SettlementProcessed
                  ? getBlockExplorerUrl(hubChainId, event.transactionHashes.SettlementProcessed)
                  : null,
              };
              break;
            case "Settlement Filled":
              eventData["Settlement Filled"] = {
                status: event.statuses.SettlementFilled || false,
                link: event.statuses.SettlementFilled
                  ? getBlockExplorerUrl(hubChainId, event.transactionHashes.SettlementFilled)
                  : null,
              };
              break;
            case "Tokens Released":
              eventData["Tokens Released"] = {
                status: event.statuses.TokensReleased || false,
                link: event.statuses.TokensReleased
                  ? getBlockExplorerUrl(
                      event.orderCreated?.data.order_fromChain,
                      event.transactionHashes.TokensReleased,
                    )
                  : null,
              };
              break;
            case "From Address":
              eventData["From Address"] = event.orderCreated?.data.order_fromAddress || "";
              break;
            case "To Address":
              eventData["To Address"] = event.orderCreated?.data.order_toAddress || "";
              break;
            case "Filler":
              eventData["Filler"] = event.orderCreated?.data.order_filler || "";
              break;
            case "From Token":
              eventData["From Token"] = event.orderCreated?.data.order_fromToken || "";
              break;
            case "To Token":
              eventData["To Token"] = event.orderCreated?.data.order_toToken || "";
              break;
            case "Expiry":
              eventData["Expiry"] = {
                timestamp: event.orderCreated?.data.order_expiry || "",
                formattedDate: convertUnixToDate(event.orderCreated?.data.order_expiry || ""),
              };
              break;
            case "From Amount":
              eventData["From Amount"] = event.orderCreated?.data.order_fromAmount || "";
              break;
            case "Fill Amount":
              eventData["Fill Amount"] = event.orderCreated?.data.order_fillAmount || "";
              break;
            case "Fee Rate":
              eventData["Fee Rate"] = event.orderCreated?.data.order_feeRate || "";
              break;
            case "From Chain":
              eventData["From Chain"] = event.orderCreated?.data.order_fromChain || "";
              break;
            case "To Chain":
              eventData["To Chain"] = event.orderCreated?.data.order_toChain || "";
              break;
            case "From Token USD":
              eventData["From Token USD"] = event.usdValueFromToken || "";
              break;
            case "To Token USD":
              eventData["To Token USD"] = event.usdValueToToken || "";
              break;
            case "Fee Value":
              eventData["Fee Value"] = event.feeValue || "";
              break;
            case "Post Hook Hash":
              eventData["Post Hook Hash"] = postHookHash;
              break;
            case "Calls":
              eventData["Post Hook Calls"] = callsData;
              break;
            default:
              eventData[header] = "";
              break;
          }
        });

        return eventData;
      }),
    );

    const jsonContent = JSON.stringify(jsonData, null, 2);

    const formattedDate = formatDateForFilename();
    const blob = new Blob([jsonContent], { type: "application/json;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `rfq_data_export_${formattedDate}.json`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleExportDropdown = () => {
    setShowExportDropdown(prev => !prev);
  };

  const handleDateRangeChange = (range: string) => {
    setDateRange(range);
    setShowDateDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDateDropdown(prev => !prev);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("columnIndex", index);
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();
    const dragIndex = event.dataTransfer.getData("columnIndex");
    if (dragIndex !== undefined && dragIndex !== dropIndex) {
      const newColumnsOrder = [...columnsOrder];
      const draggedColumn = newColumnsOrder.splice(dragIndex, 1)[0];
      newColumnsOrder.splice(dropIndex, 0, draggedColumn);
      setColumnsOrder(newColumnsOrder);
    }
  };

  const handleViewCalls = async (orderHash: string, postHookHash: string) => {
    try {
      if (postHookHash !== encodeBytes32String("")) {
        const postHooks = await fetchPostHooks([orderHash]);
        setCallsModalData(postHooks || {});
        setCallsModalOrderHash(orderHash);
        setCallsModalPostHookHash(postHookHash);
        setCallsModalVisible(true);
      }
    } catch (error) {
      console.error(`Error fetching postHook data for ${orderHash}:`, error);
    }
  };

  const handleCloseCallsModal = () => {
    setCallsModalVisible(false);
    setCallsModalData(null);
    setCallsModalOrderHash(null);
    setCallsModalPostHookHash(null);
  };

  const isWithinDateRange = (orderExpiry: string, range: string) => {
    const expiryTimestamp = parseInt(orderExpiry);
    const millisecondsInDay = 86400;
    let startRange, endRange;

    switch (range) {
      case "Today":
        const startOfToday = new Date();
        startOfToday.setHours(0, 0, 0, 0);
        startRange = startOfToday.getTime() / 1000;
        endRange = currentTimestamp;
        break;
      case "Yesterday":
        const startOfYesterday = new Date();
        startOfYesterday.setDate(startOfYesterday.getDate() - 1);
        startOfYesterday.setHours(0, 0, 0, 0);
        startRange = startOfYesterday.getTime() / 1000;
        endRange = startRange + millisecondsInDay;
        break;
      case "Last 7 days":
        startRange = currentTimestamp - 7 * millisecondsInDay;
        endRange = currentTimestamp;
        break;
      case "Last 30 days":
        startRange = currentTimestamp - 30 * millisecondsInDay;
        endRange = currentTimestamp;
        break;
      case "Last 60 days":
        startRange = currentTimestamp - 60 * millisecondsInDay;
        endRange = currentTimestamp;
        break;
      case "Last 90 days":
        startRange = currentTimestamp - 90 * millisecondsInDay;
        endRange = currentTimestamp;
        break;
      case "This year":
        const startOfYear = new Date();
        startOfYear.setMonth(0, 1);
        startOfYear.setHours(0, 0, 0, 0);
        startRange = startOfYear.getTime() / 1000;
        endRange = currentTimestamp;
        break;
      case "Last year":
        const currentYear = new Date().getFullYear();
        const startOfLastYear = new Date(currentYear - 1, 0, 1);
        const endOfLastYear = new Date(currentYear, 0, 1);
        startRange = startOfLastYear.getTime() / 1000;
        endRange = endOfLastYear.getTime() / 1000;
        break;
      default:
        return true;
    }

    return expiryTimestamp >= startRange && expiryTimestamp < endRange;
  };

  const [filters, setFilters] = useState<FilterState>({
    orderHash: "",
    fromChain: "",
    toChain: "",
    fromAddress: "",
    toAddress: "",
    filler: "",
    fromToken: "",
    toToken: "",
    statusFilter: {
      OrderRefunded: "",
      OrderFilled: "",
      SettlementForwarded: "",
      SettlementProcessed: "",
      SettlementFilled: "",
      TokensReleased: "",
    },
    expiry: { date: "", beforeAfter: "" },
    expiryTimestamp: { timestamp: "", beforeAfter: "" },
    fromAmount: { value: "", greaterLessEqual: "" },
    fillAmount: { value: "", greaterLessEqual: "" },
    feeRate: { value: "", greaterLessEqual: "" },
    transactionHashes: "",
  });

  const resetFilters = () => {
    setFilters({
      orderHash: "",
      fromChain: "",
      toChain: "",
      fromAddress: "",
      toAddress: "",
      filler: "",
      fromToken: "",
      toToken: "",
      statusFilter: {
        OrderRefunded: "",
        OrderFilled: "",
        SettlementForwarded: "",
        SettlementProcessed: "",
        SettlementFilled: "",
        TokensReleased: "",
      },
      expiry: { date: "", beforeAfter: "" },
      expiryTimestamp: { timestamp: "", beforeAfter: "" },
      fromAmount: { value: "", greaterLessEqual: "" },
      fillAmount: { value: "", greaterLessEqual: "" },
      feeRate: { value: "", greaterLessEqual: "" },
      transactionHashes: "",
    });

    setSelectedOrders([]);
    setSortConfig({ key: "blocktimestamp", direction: "descending" });
    setHideRefunded(false);
    setHideCompleted(false);
    setColumnsOrder(columnHeaders);
    setVisibleColumns(
      columnHeaders.reduce((acc, header) => {
        acc[header] = true;
        return acc;
      }, {}),
    );
    setDateRange("All time");
  };

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const data = await fetchDashboardData();

        setTokens(data.tokens);

        const enrichedData = data.events
          .filter(event => {
            const fromToken = event.orderCreated?.data.order_fromToken;
            const toToken = event.orderCreated?.data.order_toToken;
            return (
              isTokenSupported(data.tokens, fromToken) && isTokenSupported(data.tokens, toToken)
            );
          })
          .map(event => enrichOrderData(data.tokens, event));

        setRfqEvents(enrichedData);
      } catch (error) {
        console.error("Failed to load dashboard data:", error);
      }
    };

    loadDashboardData();
  }, []);

  useEffect(() => {
    const handleChainChanged = async () => {
      await connectWallet();
      console.log("Network changed");
    };

    window.ethereum?.on("chainChanged", handleChainChanged);

    return () => {
      window.ethereum?.removeListener("chainChanged", handleChainChanged);
    };
  }, [connectWallet]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDateDropdown(false);
      }

      if (exportDropdownRef.current && !exportDropdownRef.current.contains(event.target as Node)) {
        setShowExportDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDateDropdown, showExportDropdown]);

  const handleInputChange = (key: keyof FilterState, value: string) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleNestedChange = (
    group: keyof Pick<
      FilterState,
      "expiry" | "expiryTimestamp" | "fromAmount" | "fillAmount" | "feeRate"
    >,
    key: "date" | "beforeAfter" | "timestamp" | "value" | "greaterLessEqual",
    value: string,
  ) => {
    setFilters(prev => ({
      ...prev,
      [group]: {
        ...prev[group],
        [key]: value,
      },
    }));
  };

  const handleDateChange = (key: keyof FilterState["expiry"], value: string) => {
    setFilters(prev => ({
      ...prev,
      expiry: {
        ...prev.expiry,
        [key]: value,
      },
      expiryTimestamp: { timestamp: "", beforeAfter: "" },
    }));
  };

  const handleTimestampChange = (key: keyof FilterState["expiryTimestamp"], value: string) => {
    setFilters(prev => ({
      ...prev,
      expiryTimestamp: {
        ...prev.expiryTimestamp,
        [key]: value,
      },
      expiry: { date: "", beforeAfter: "" },
    }));
  };

  const handleStatusChange = (statusName: keyof FilterState["statusFilter"], value: string) => {
    setFilters(prev => ({
      ...prev,
      statusFilter: {
        ...prev.statusFilter,
        [statusName]: value as StatusBoolean,
      },
    }));
  };

  const handleHideRefunded = () => {
    setHideRefunded(!hideRefunded);
  };

  const handleHideCompleted = () => {
    setHideCompleted(!hideCompleted);
  };

  const handleRowSelection = (order: ExtendedRFQEventData) => {
    const orderRefunded = order.statuses.OrderRefunded;
    const tokensReleased = order.statuses.TokensReleased;
    const orderFilled = order.statuses.OrderFilled;

    if (orderRefunded || tokensReleased || !orderFilled) return;

    setSelectedOrders(prevSelected => {
      const alreadySelected = prevSelected.some(
        selected => selected.orderCreated?.orderHash === order.orderCreated?.orderHash,
      );

      if (alreadySelected) {
        return prevSelected.filter(
          selected => selected.orderCreated?.orderHash !== order.orderCreated?.orderHash,
        );
      } else {
        return [...prevSelected, order];
      }
    });
  };

  const filteredEvents = rfqEvents.filter(event => {
    const { orderCreated, transactionHashes, statuses } = event;

    const containsAny = (valueString: string, targetValue: string) => {
      if (!valueString || !targetValue) return false;
      const filters = valueString.split(",").map(v => v.trim().toLowerCase());
      return filters.some(filterValue => filterValue === targetValue.toLowerCase());
    };

    if (
      filters.transactionHashes &&
      !Object.values(transactionHashes).some(hash => containsAny(filters.transactionHashes, hash))
    ) {
      return false;
    }

    if (filters.orderHash && !containsAny(filters.orderHash, orderCreated?.orderHash)) {
      return false;
    }

    if (filters.fromChain && !containsAny(filters.fromChain, orderCreated?.data.order_fromChain)) {
      return false;
    }

    if (filters.toChain && !containsAny(filters.toChain, orderCreated?.data.order_toChain)) {
      return false;
    }

    if (
      filters.fromAddress &&
      !containsAny(filters.fromAddress, orderCreated?.data.order_fromAddress)
    ) {
      return false;
    }

    if (filters.toAddress && !containsAny(filters.toAddress, orderCreated?.data.order_toAddress)) {
      return false;
    }

    if (filters.filler && !containsAny(filters.filler, orderCreated?.data.order_filler)) {
      return false;
    }

    if (filters.fromToken && !containsAny(filters.fromToken, orderCreated?.data.order_fromToken)) {
      return false;
    }

    if (filters.toToken && !containsAny(filters.toToken, orderCreated?.data.order_toToken)) {
      return false;
    }

    if (hideRefunded && statuses.OrderRefunded) return false;
    if (hideCompleted && statuses.TokensReleased) return false;

    for (const status in statuses) {
      if (
        filters.statusFilter[status as keyof typeof statuses] !== "" &&
        statuses[status as keyof typeof statuses] !==
          (filters.statusFilter[status as keyof typeof statuses] === "true")
      )
        return false;
    }

    if (filters.expiry.date) {
      const expiryDate = parseInt(orderCreated?.data.order_expiry || "0") * 1000;
      const filterDate = new Date(filters.expiry.date).getTime();
      if (filters.expiry.beforeAfter === "before" && expiryDate >= filterDate) return false;
      if (filters.expiry.beforeAfter === "after" && expiryDate <= filterDate) return false;
    }

    if (filters.expiryTimestamp.timestamp) {
      const expiryTimestamp = parseInt(orderCreated?.data.order_expiry || "0");
      const filterTimestamp = parseInt(filters.expiryTimestamp.timestamp);
      if (filters.expiryTimestamp.beforeAfter === "before" && expiryTimestamp >= filterTimestamp)
        return false;
      if (filters.expiryTimestamp.beforeAfter === "after" && expiryTimestamp <= filterTimestamp)
        return false;
    }

    const fromAmount = parseFloat(orderCreated?.data.order_fromAmount || "0");
    const fillAmount = parseFloat(orderCreated?.data.order_fillAmount || "0");
    const feeRate = parseFloat(orderCreated?.data.order_feeRate || "0");

    if (filters.fromAmount.value) {
      const filterValue = parseFloat(filters.fromAmount.value);
      if (
        (filters.fromAmount.greaterLessEqual === "greater" && !(fromAmount > filterValue)) ||
        (filters.fromAmount.greaterLessEqual === "less" && !(fromAmount < filterValue)) ||
        (filters.fromAmount.greaterLessEqual === "equal" && !(fromAmount === filterValue))
      )
        return false;
    }

    if (filters.fillAmount.value) {
      const filterValue = parseFloat(filters.fillAmount.value);
      if (
        (filters.fillAmount.greaterLessEqual === "greater" && !(fillAmount > filterValue)) ||
        (filters.fillAmount.greaterLessEqual === "less" && !(fillAmount < filterValue)) ||
        (filters.fillAmount.greaterLessEqual === "equal" && !(fillAmount === filterValue))
      )
        return false;
    }

    if (filters.feeRate.value) {
      const filterValue = parseFloat(filters.feeRate.value);
      if (
        (filters.feeRate.greaterLessEqual === "greater" && !(feeRate > filterValue)) ||
        (filters.feeRate.greaterLessEqual === "less" && !(feeRate < filterValue)) ||
        (filters.feeRate.greaterLessEqual === "equal" && !(feeRate === filterValue))
      )
        return false;
    }

    if (dateRange && !isWithinDateRange(orderCreated?.data.order_expiry || "0", dateRange)) {
      return false;
    }

    return true;
  });

  const startIndex = (currentPage - 1) * pageSize;
  const totalPages = Math.ceil(filteredEvents.length / pageSize);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  async function handleRefund(orderHash, orderData) {
    if (!walletAddress) {
      alert("Please connect your wallet first.");
      return;
    }

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = createSpokeContractInstance(signer);

      const order = {
        fromAddress: orderData.order_fromAddress,
        toAddress: orderData.order_toAddress,
        filler: orderData.order_filler,
        fromToken: orderData.order_fromToken,
        toToken: orderData.order_toToken,
        expiry: orderData.order_expiry,
        fromAmount: orderData.order_fromAmount,
        fillAmount: orderData.order_fillAmount,
        feeRate: orderData.order_feeRate,
        fromChain: orderData.order_fromChain,
        toChain: orderData.order_toChain,
        postHookHash: orderData.order_postHookHash,
      };

      const transactionResponse = await contract.refundOrder(order);
      console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

      const receipt = await transactionResponse.wait();
      console.log("Transaction confirmed:", receipt);

      setRfqEvents(prevEvents =>
        prevEvents.map(event =>
          event.orderCreated?.orderHash === orderHash
            ? {
                ...event,
                statuses: {
                  ...event.statuses,
                  OrderRefunded: true,
                },
                transactionHashes: {
                  ...event.transactionHashes,
                  OrderRefunded: transactionResponse.hash,
                },
              }
            : event,
        ),
      );
    } catch (error) {
      console.error("Error executing refundOrder:", error);
    }
  }

  async function handleBatchToHub() {
    if (!walletAddress) {
      alert("Please connect your wallet first.");
      return;
    }

    const orderHashes = selectedOrders
      .filter(
        order =>
          order.statuses.OrderFilled &&
          order.orderCreated?.data.order_toChain === chainId?.toString(),
      )
      .map(order => order.orderCreated?.orderHash);

    if (orderHashes.length === 0) {
      alert("No eligible orders to forward");
      return;
    }

    const parsedGasLimit = parseInt(gasLimit);
    if (isNaN(parsedGasLimit)) {
      alert("Please enter a valid gas limit.");
      return;
    }

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = createSpokeContractInstance(signer);
      const value = ethers.parseEther(ethValue || "0");

      const transactionResponse = await contract.forwardSettlements(orderHashes, {
        value: value,
        gasLimit: parsedGasLimit,
      });
      console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

      await transactionResponse.wait();
      console.log("Transaction confirmed");

      setRfqEvents(prevEvents =>
        prevEvents.map(event =>
          orderHashes.includes(event.orderCreated?.orderHash)
            ? {
                ...event,
                statuses: {
                  ...event.statuses,
                  SettlementForwarded: true,
                },
                transactionHashes: {
                  ...event.transactionHashes,
                  SettlementForwarded: transactionResponse.hash,
                },
              }
            : event,
        ),
      );
    } catch (error) {
      console.error("Error executing forwardSettlements:", error);
      console.error("Error data:", error.data || error.message);
    }
  }

  async function handleBatchToSpoke() {
    if (!walletAddress) {
      alert("Please connect your wallet first.");
      return;
    }

    if (chainId?.toString() !== hubChainId) {
      alert("Please switch to Hub chain first.");
      return;
    }

    const eligibleOrders = selectedOrders.filter(
      order =>
        order.statuses.SettlementProcessed &&
        order.orderCreated?.data.order_fromChain === customChainId,
    );
    console.log("eligible orders", eligibleOrders);

    if (eligibleOrders.length === 0) {
      alert("No eligible orders to forward");
      return;
    }

    const orders = eligibleOrders.map(order => ({
      fromAddress: order.orderCreated?.data.order_fromAddress,
      toAddress: order.orderCreated?.data.order_toAddress,
      filler: order.orderCreated?.data.order_filler,
      fromToken: order.orderCreated?.data.order_fromToken,
      toToken: order.orderCreated?.data.order_toToken,
      expiry: order.orderCreated?.data.order_expiry,
      fromAmount: order.orderCreated?.data.order_fromAmount,
      fillAmount: order.orderCreated?.data.order_fillAmount,
      feeRate: order.orderCreated?.data.order_feeRate,
      fromChain: order.orderCreated?.data.order_fromChain,
      toChain: order.orderCreated?.data.order_toChain,
      postHookHash: order.orderCreated?.data.order_postHookHash,
    }));
    console.log("orders", orders);

    const tokens = [
      ...new Set(eligibleOrders.map(order => order.orderCreated?.data.order_fromToken)),
    ];
    console.log("tokens", tokens);

    const parsedGasLimit = parseInt(gasLimit);
    if (isNaN(parsedGasLimit)) {
      alert("Please enter a valid gas limit.");
      return;
    }
    console.log("parsedGasLimit", parsedGasLimit);

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = createHubContractInstance(signer);
      const value = ethers.parseEther(ethValue || "0");
      console.log("value", value);
      console.log("fillerAddress", fillerAddress);
      console.log("customChainId", customChainId);

      if (tokens.length === 1) {
        const transactionResponse = await contract.forwardSettlements(
          orders,
          customChainId,
          tokens,
          fillerAddress,
          {
            value: value,
            gasLimit: parsedGasLimit,
          },
        );
        console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

        await transactionResponse.wait();
        console.log("Transaction confirmed");

        setRfqEvents(prevEvents =>
          prevEvents.map(event =>
            eligibleOrders.some(
              order => order.orderCreated?.orderHash === event.orderCreated?.orderHash,
            )
              ? {
                  ...event,
                  statuses: { ...event.statuses, SettlementFilled: true },
                  transactionHashes: {
                    ...event.transactionHashes,
                    SettlementFilled: transactionResponse.hash,
                  },
                }
              : event,
          ),
        );
      } else if (tokens.length > 1) {
        const transactionResponse = await contract.forwardMultiTokenSettlements(
          orders,
          customChainId,
          tokens,
          fillerAddress,
          {
            value: value,
            gasLimit: parsedGasLimit,
          },
        );
        console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

        await transactionResponse.wait();
        console.log("Transaction confirmed");

        setRfqEvents(prevEvents =>
          prevEvents.map(event =>
            eligibleOrders.some(
              order => order.orderCreated?.orderHash === event.orderCreated?.orderHash,
            )
              ? {
                  ...event,
                  statuses: { ...event.statuses, SettlementFilled: true },
                  transactionHashes: {
                    ...event.transactionHashes,
                    SettlementFilled: transactionResponse.hash,
                  },
                }
              : event,
          ),
        );
      }
    } catch (error) {
      console.error("Error executing hub to spoke batching:", error);
      console.error("Error data:", error.data || error.message);
    }
  }

  async function handleFill(orderHash, orderData) {
    if (!walletAddress) {
      alert("Please connect your wallet first.");
      return;
    }

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = createSpokeContractInstance(signer);

      const order = {
        fromAddress: orderData.order_fromAddress,
        toAddress: orderData.order_toAddress,
        filler: orderData.order_filler,
        fromToken: orderData.order_fromToken,
        toToken: orderData.order_toToken,
        expiry: orderData.order_expiry,
        fromAmount: orderData.order_fromAmount,
        fillAmount: orderData.order_fillAmount,
        feeRate: orderData.order_feeRate,
        fromChain: orderData.order_fromChain,
        toChain: orderData.order_toChain,
        postHookHash: orderData.order_postHookHash,
      };

      if (orderData.order_postHookHash === encodeBytes32String("")) {
        let transactionResponse: ethers.TransactionResponse;
        if (
          orderData.order_toToken.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        ) {
          transactionResponse = await contract.fillOrder(order, [], {
            value: orderData.order_fillAmount,
          });
        } else {
          transactionResponse = await contract.fillOrder(order, []);
        }
        console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

        const receipt = await transactionResponse.wait();
        console.log("Transaction confirmed:", receipt);

        setRfqEvents(prevEvents =>
          prevEvents.map(event =>
            event.orderCreated?.orderHash === orderHash
              ? {
                  ...event,
                  statuses: {
                    ...event.statuses,
                    OrderFilled: true,
                  },
                  transactionHashes: {
                    ...event.transactionHashes,
                    OrderFilled: transactionResponse.hash,
                  },
                }
              : event,
          ),
        );
      } else {
        const postHooks = await fetchPostHooks([orderHash]);
        const postHookData = postHooks as { calls: any };

        const calls = postHookData.calls;
        console.log("calls:", calls);

        const transactionResponse = await contract.fillOrder(order, calls);
        console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

        const receipt = await transactionResponse.wait();
        console.log("Transaction confirmed:", receipt);

        setRfqEvents(prevEvents =>
          prevEvents.map(event =>
            event.orderCreated?.orderHash === orderHash
              ? {
                  ...event,
                  statuses: {
                    ...event.statuses,
                    OrderFilled: true,
                  },
                  transactionHashes: {
                    ...event.transactionHashes,
                    OrderFilled: transactionResponse.hash,
                  },
                }
              : event,
          ),
        );
      }
    } catch (error) {
      console.error("Error executing fillOrder:", error);

      if (
        error.code === "CALL_EXCEPTION" &&
        error.reason.includes("ERC20: transfer amount exceeds allowance")
      ) {
        alert(`${orderData.order_toToken} allowance is too low. Please approve first.`);
      } else {
        alert("An unexpected error occurred. Please try again.");
      }
    }
  }

  async function handleApprove(orderHash, orderData) {
    if (!walletAddress) {
      alert("Please connect your wallet first.");
      return;
    }

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = createTokenContractInstance(signer, orderData.order_toToken);

      const transactionResponse = await contract.approve(spokeAddress, orderData.order_fillAmount);
      console.log("Transaction submitted, waiting for confirmation:", transactionResponse.hash);

      const receipt = await transactionResponse.wait();
      console.log("Transaction confirmed:", receipt);
    } catch (error) {
      console.error("Error executing approve:", error);
    }
  }

  const getComparator = (direction: "ascending" | "descending") => {
    return (a: any, b: any) => {
      if (a < b) return direction === "ascending" ? -1 : 1;
      if (a > b) return direction === "ascending" ? 1 : -1;
      return 0;
    };
  };

  const sortByColumnKey = (key: string, direction: "ascending" | "descending") => {
    setSortConfig({ key, direction });
  };

  const sortedEvents = [...filteredEvents].sort((a, b) => {
    if (!sortConfig) {
      setSortConfig({ key: "blocktimestamp", direction: "descending" });
      return 0;
    }

    const { key, direction } = sortConfig;
    let aValue, bValue;

    switch (key) {
      case "blocktimestamp":
        aValue = new Date(a.orderCreated?.blockTimestamp || 0).getTime();
        bValue = new Date(b.orderCreated?.blockTimestamp || 0).getTime();
        break;
      case "orderhash":
        aValue = a.orderCreated?.orderHash;
        bValue = b.orderCreated?.orderHash;
        break;
      case "fromaddress":
        aValue = a.orderCreated?.data.order_fromAddress || "";
        bValue = b.orderCreated?.data.order_fromAddress || "";
        break;
      case "toaddress":
        aValue = a.orderCreated?.data.order_toAddress || "";
        bValue = b.orderCreated?.data.order_toAddress || "";
        break;
      case "filler":
        aValue = a.orderCreated?.data.order_filler || "";
        bValue = b.orderCreated?.data.order_filler || "";
        break;
      case "fromtoken":
        aValue = a.orderCreated?.data.order_fromToken || "";
        bValue = b.orderCreated?.data.order_fromToken || "";
        break;
      case "totoken":
        aValue = a.orderCreated?.data.order_toToken || "";
        bValue = b.orderCreated?.data.order_toToken || "";
        break;
      case "orderrefunded":
        aValue = a.statuses.OrderRefunded ? "true" : "false";
        bValue = b.statuses.OrderRefunded ? "true" : "false";
        break;
      case "orderfilled":
        aValue = a.statuses.OrderFilled ? "true" : "false";
        bValue = b.statuses.OrderFilled ? "true" : "false";
        break;
      case "settlementforwarded":
        aValue = a.statuses.SettlementForwarded ? "true" : "false";
        bValue = b.statuses.SettlementForwarded ? "true" : "false";
        break;
      case "settlementprocessed":
        aValue = a.statuses.SettlementProcessed ? "true" : "false";
        bValue = b.statuses.SettlementProcessed ? "true" : "false";
        break;
      case "settlementfilled":
        aValue = a.statuses.SettlementFilled ? "true" : "false";
        bValue = b.statuses.SettlementFilled ? "true" : "false";
        break;
      case "tokensreleased":
        aValue = a.statuses.TokensReleased ? "true" : "false";
        bValue = b.statuses.TokensReleased ? "true" : "false";
        break;
      case "expiry":
        aValue = new Date(parseInt(a.orderCreated?.data.order_expiry || "0") * 1000);
        bValue = new Date(parseInt(b.orderCreated?.data.order_expiry || "0") * 1000);
        break;
      case "fromamount":
        aValue = parseFloat(a.orderCreated?.data.order_fromAmount || "0");
        bValue = parseFloat(b.orderCreated?.data.order_fromAmount || "0");
        break;
      case "fillamount":
        aValue = parseFloat(a.orderCreated?.data.order_fillAmount || "0");
        bValue = parseFloat(b.orderCreated?.data.order_fillAmount || "0");
        break;
      case "feerate":
        aValue = parseFloat(a.orderCreated?.data.order_feeRate || "0");
        bValue = parseFloat(b.orderCreated?.data.order_feeRate || "0");
        break;
      case "fromchain":
        aValue = a.orderCreated?.data.order_fromChain.toString() || "";
        bValue = b.orderCreated?.data.order_fromChain.toString() || "";
        break;
      case "tochain":
        aValue = a.orderCreated?.data.order_toChain.toString() || "";
        bValue = b.orderCreated?.data.order_toChain.toString() || "";
        break;
      case "fromtokenusd":
        aValue = parseFloat(a.usdValueFromToken.replace("$", "")) || 0;
        bValue = parseFloat(b.usdValueFromToken.replace("$", "")) || 0;
        break;
      case "totokenusd":
        aValue = parseFloat(a.usdValueToToken.replace("$", "")) || 0;
        bValue = parseFloat(b.usdValueToToken.replace("$", "")) || 0;
        break;
      case "feevalue":
        aValue = parseFloat(a.feeValue.replace("$", "")) || 0;
        bValue = parseFloat(b.feeValue.replace("$", "")) || 0;
        break;
      case "posthookhash":
        aValue = a.orderCreated?.data.order_postHookHash.toString() || "";
        bValue = b.orderCreated?.data.order_postHookHash.toString() || "";
        break;
      case "calls":
        aValue = a.orderCreated?.data.order_postHookHash.toString() || "";
        bValue = b.orderCreated?.data.order_postHookHash.toString() || "";
        break;
      default:
        aValue = a.orderCreated?.data[`order_${key}`]?.toString()?.toLowerCase() || "";
        bValue = b.orderCreated?.data[`order_${key}`]?.toString()?.toLowerCase() || "";
        break;
    }

    return getComparator(direction)(aValue, bValue);
  });

  const SortButton: React.FC<{ columnKey: string }> = ({ columnKey }) => (
    <button
      onClick={() => {
        const isCurrentColumn = sortConfig?.key === columnKey;
        const currentDirection = sortConfig?.direction;

        let newDirection: "ascending" | "descending" = "ascending";
        if (isCurrentColumn) {
          newDirection = currentDirection === "ascending" ? "descending" : "ascending";
        }

        sortByColumnKey(columnKey, newDirection);
      }}
      style={{
        ...buttonStyle,
        marginLeft: "5px",
        display: hoverColumn === columnKey ? "block" : "none",
        float: "right",
      }}
    >
      {sortConfig?.key === columnKey ? (sortConfig.direction === "ascending" ? "↑" : "↓") : "⇅"}
    </button>
  );

  const paginatedSortedEvents = sortedEvents.slice(startIndex, startIndex + pageSize);

  function renderBatchInfo() {
    if (chainId === null) {
      return <p>Please connect your wallet to see batch information.</p>;
    }

    const hubOrders = selectedOrders.filter(order => order.statuses.SettlementProcessed);
    const spokeOrders = selectedOrders.filter(order => {
      const matchesChain = order.orderCreated?.data.order_toChain === chainId?.toString();
      return matchesChain && order.statuses.OrderFilled && !order.statuses.SettlementForwarded;
    });

    const hubCount = hubOrders.length;
    const spokeCount = spokeOrders.length;

    const totalUsdValueHub = hubOrders.reduce((total, order) => {
      return total + parseFloat(order.usdValueFromToken.replace("$", ""));
    }, 0);

    const totalUsdValueSpoke = spokeOrders.reduce((total, order) => {
      return total + parseFloat(order.usdValueToToken.replace("$", ""));
    }, 0);

    const whiteCellStyle = {
      ...tdStyle,
    };

    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "var(--st-color-royal-500)",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <div style={batchControlContainerStyle}>
          <span style={{ fontSize: "16px", marginRight: "10px" }}>Batch from {networkName}</span>
          <Input
            showIcon={false}
            type="number"
            step="0.01"
            value={ethValue}
            onChange={e => setEthValue(e.target.value)}
            placeholder="Value"
            style={batchValueInputStyle}
          />
          <Input
            showIcon={false}
            type="number"
            step="1000"
            value={gasLimit}
            onChange={e => setGasLimit(e.target.value)}
            placeholder="Gas Limit"
            style={batchGasLimitInputStyle}
          />
          <Input
            showIcon={false}
            type="text"
            value={fillerAddress}
            onChange={e => setFillerAddress(e.target.value)}
            placeholder="Filler Address"
            style={batchFillerAddressInputStyle}
          />
          <Input
            showIcon={false}
            type="text"
            value={customChainId}
            onChange={e => setCustomChainId(e.target.value)}
            placeholder="Chain ID"
            style={batchChainIdInputStyle}
          />

          <button style={disconnectButtonStyle} onClick={handleBatchToHub}>
            Forward to Hub
          </button>
          <button style={disconnectButtonStyle} onClick={handleBatchToSpoke}>
            Forward to Spoke
          </button>
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
          <thead>
            <tr>
              <th style={{ ...thStyle, textAlign: "center" }}># Orders Selected</th>
              <th style={{ ...thStyle, textAlign: "center" }}>On Hub</th>
              <th style={{ ...thStyle, textAlign: "center" }}>On Spoke</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ ...whiteCellStyle, textAlign: "center" }}>{selectedOrders.length}</td>
              <td style={{ ...whiteCellStyle, textAlign: "center" }}>{hubCount}</td>
              <td style={{ ...whiteCellStyle, textAlign: "center" }}>{spokeCount}</td>
            </tr>
            <tr>
              <td style={{ ...whiteCellStyle, textAlign: "center" }}>USD Value</td>
              <td style={{ ...whiteCellStyle, textAlign: "center" }}>
                ${totalUsdValueHub.toFixed(4)}
              </td>
              <td style={{ ...whiteCellStyle, textAlign: "center" }}>
                ${totalUsdValueSpoke.toFixed(4)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div style={containerStyle} className="tw-overflow-hidden">
      <h1 style={headerStyle}>Squid RFQ Dashboard</h1>

      <div style={mainContainerStyle}>
        <div style={filterContainerStyle}>
          {walletAddress && (
            <div
              style={{
                ...infoStyle,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p>Address: {walletAddress}</p>
                <p>Connected Chain ID: {chainId}</p>
                <p>Network Name: {networkName}</p>
              </div>
              <button
                onClick={disconnectWallet}
                style={{ ...disconnectButtonStyle, marginLeft: "auto" }}
              >
                Disconnect
              </button>
            </div>
          )}

          {!walletAddress && (
            <ButtonFeedback onClick={connectWallet} style={connectButtonStyle}>
              Connect Wallet
            </ButtonFeedback>
          )}

          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.orderHash}
                onChange={value => handleInputChange("orderHash", value)}
                options={[...new Set(rfqEvents.map(event => event.orderCreated?.orderHash || ""))]}
                placeholder="Filter by Order Hash"
              />
            </div>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.fromAddress}
                onChange={value => handleInputChange("fromAddress", value)}
                options={[
                  ...new Set(
                    rfqEvents.map(event => event.orderCreated?.data.order_fromAddress || ""),
                  ),
                ]}
                placeholder="Filter by From Address"
              />
            </div>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.toAddress}
                onChange={value => handleInputChange("toAddress", value)}
                options={[
                  ...new Set(
                    rfqEvents.map(event => event.orderCreated?.data.order_toAddress || ""),
                  ),
                ]}
                placeholder="Filter by To Address"
              />
            </div>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.filler}
                onChange={value => handleInputChange("filler", value)}
                options={[
                  ...new Set(rfqEvents.map(event => event.orderCreated?.data.order_filler || "")),
                ]}
                placeholder="Filter by Filler"
              />
            </div>
          </div>

          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.fromToken}
                onChange={value => handleInputChange("fromToken", value)}
                options={[
                  ...new Set(
                    rfqEvents.map(event => event.orderCreated?.data.order_fromToken || ""),
                  ),
                ]}
                placeholder="Filter by From Token"
              />
            </div>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.toToken}
                onChange={value => handleInputChange("toToken", value)}
                options={[
                  ...new Set(rfqEvents.map(event => event.orderCreated?.data.order_toToken || "")),
                ]}
                placeholder="Filter by To Token"
              />
            </div>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.fromChain}
                onChange={value => handleInputChange("fromChain", value)}
                options={[
                  ...new Set(
                    rfqEvents.map(
                      event => event.orderCreated?.data.order_fromChain.toString() || "",
                    ),
                  ),
                ]}
                placeholder="Filter by From Chain ID"
              />
            </div>
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <FilterInput
                value={filters.toChain}
                onChange={value => handleInputChange("toChain", value)}
                options={[
                  ...new Set(
                    rfqEvents.map(event => event.orderCreated?.data.order_toChain.toString() || ""),
                  ),
                ]}
                placeholder="Filter by To Chain ID"
              />
            </div>
            <FilterInput
              value={filters.transactionHashes}
              onChange={value => handleInputChange("transactionHashes", value)}
              options={transactionHashOptions}
              placeholder="Filter by Transaction Hash"
            />
          </div>

          <div>
            {[
              "OrderRefunded",
              "OrderFilled",
              "SettlementForwarded",
              "SettlementProcessed",
              "SettlementFilled",
              "TokensReleased",
            ].map(statusKey => (
              <select
                key={statusKey}
                style={selectStyle}
                value={filters.statusFilter[statusKey] || ""}
                onChange={e => handleStatusChange(statusKey, e.target.value)}
              >
                <option value="">Status {statusKey}</option>
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            ))}
          </div>

          <div>
            <input
              style={inputStyle}
              type="date"
              placeholder="Filter by Expiry Date"
              value={filters.expiry.date}
              onChange={e => handleDateChange("date", e.target.value)}
            />
            <select
              style={selectStyle}
              value={filters.expiry.beforeAfter}
              onChange={e => handleDateChange("beforeAfter", e.target.value)}
            >
              <option value="">Before/After</option>
              <option value="before">Before</option>
              <option value="after">After</option>
            </select>
            <Input
              showIcon={false}
              style={inputStyle}
              type="text"
              placeholder="Filter by Expiry Unix Timestamp"
              value={filters.expiryTimestamp.timestamp}
              onChange={e => handleTimestampChange("timestamp", e.target.value)}
            />
            <select
              style={selectStyle}
              value={filters.expiryTimestamp.beforeAfter}
              onChange={e => handleTimestampChange("beforeAfter", e.target.value)}
            >
              <option value="">Before/After</option>
              <option value="before">Before</option>
              <option value="after">After</option>
            </select>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Input
              showIcon={false}
              style={inputStyle}
              type="number"
              placeholder="Filter by From Amount"
              value={filters.fromAmount.value}
              onChange={e => handleNestedChange("fromAmount", "value", e.target.value)}
            />
            <select
              style={selectStyle}
              value={filters.fromAmount.greaterLessEqual}
              onChange={e => handleNestedChange("fromAmount", "greaterLessEqual", e.target.value)}
            >
              <option value="">Option</option>
              <option value="greater">Greater than</option>
              <option value="less">Less than</option>
              <option value="equal">Equal to</option>
            </select>
            <Input
              showIcon={false}
              style={inputStyle}
              type="number"
              placeholder="Filter by Fill Amount"
              value={filters.fillAmount.value}
              onChange={e => handleNestedChange("fillAmount", "value", e.target.value)}
            />
            <select
              style={selectStyle}
              value={filters.fillAmount.greaterLessEqual}
              onChange={e => handleNestedChange("fillAmount", "greaterLessEqual", e.target.value)}
            >
              <option value="">Option</option>
              <option value="greater">Greater than</option>
              <option value="less">Less than</option>
              <option value="equal">Equal to</option>
            </select>
            <Input
              showIcon={false}
              style={inputStyle}
              type="number"
              placeholder="Filter by Fee Rate"
              value={filters.feeRate.value}
              onChange={e => handleNestedChange("feeRate", "value", e.target.value)}
            />
            <select
              style={selectStyle}
              value={filters.feeRate.greaterLessEqual}
              onChange={e => handleNestedChange("feeRate", "greaterLessEqual", e.target.value)}
            >
              <option value="">Option</option>
              <option value="greater">Greater than</option>
              <option value="less">Less than</option>
              <option value="equal">Equal to</option>
            </select>
          </div>
          <div style={batchInfoContainerStyle}>{renderBatchInfo()}</div>
          <div style={controlContainerStyle}>
            <label style={{ flexShrink: 0 }}>Rows per page:</label>
            <select style={selectStyle} value={pageSize} onChange={handlePageSizeChange}>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <ButtonFeedback style={resetButtonStyle} onClick={refreshRfqEvents}>
              Refresh
            </ButtonFeedback>
            <ButtonFeedback style={resetButtonStyle} onClick={resetFilters}>
              Reset Filters
            </ButtonFeedback>
            <button style={resetButtonStyle} onClick={handleHideRefunded}>
              {hideRefunded ? "Show Refunded" : "Hide Refunded"}
            </button>
            <button style={resetButtonStyle} onClick={handleHideCompleted}>
              {hideCompleted ? "Show Completed" : "Hide Completed"}
            </button>
            <div style={{ position: "relative" }} ref={dropdownRef}>
              <ButtonFeedback style={resetButtonStyle} onClick={toggleDropdown}>
                {dateRange}
              </ButtonFeedback>
              {showDateDropdown && (
                <ul style={dateRangeStyle}>
                  {[
                    "All time",
                    "Today",
                    "Yesterday",
                    "Last 7 days",
                    "Last 30 days",
                    "Last 60 days",
                    "Last 90 days",
                    "This year",
                    "Last year",
                  ].map(range => (
                    <li
                      key={range}
                      style={{
                        padding: "8px 16px",
                        cursor: "pointer",
                      }}
                      onMouseOver={e => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                      onMouseOut={e => (e.currentTarget.style.backgroundColor = "transparent")}
                      onClick={() => handleDateRangeChange(range)}
                    >
                      {range}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div style={{ position: "relative" }} ref={exportDropdownRef}>
              <ButtonFeedback style={exportButtonStyle} onClick={toggleExportDropdown}>
                Export
              </ButtonFeedback>
              {showExportDropdown && (
                <ul style={dateRangeStyle}>
                  <li
                    style={{
                      padding: "8px 16px",
                      cursor: "pointer",
                    }}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = "transparent")}
                    onClick={handleExportToCSV}
                  >
                    Export to CSV
                  </li>
                  <li
                    style={{
                      padding: "8px 16px",
                      cursor: "pointer",
                    }}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = "transparent")}
                    onClick={handleExportToJSON}
                  >
                    Export to JSON
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div style={analyticsContainerStyle}>
          <Analytics rfqEvents={rfqEvents} />
        </div>
      </div>

      {callsModalVisible && (
        <div style={callsModalBackgroundStyle} onClick={handleCloseCallsModal}>
          <div style={callsModalContentStyle} onClick={e => e.stopPropagation()}>
            <button style={closeCallsButtonStyle} onClick={handleCloseCallsModal}>
              ×
            </button>

            <div style={callsDetailsStyle}>
              <div style={callsLabelStyle}>Order Hash</div>
              <div style={callsContentStyle}>{callsModalOrderHash}</div>
              <div style={callsLabelStyle}>Post Hook Hash</div>
              <div style={callsContentStyle}>{callsModalPostHookHash}</div>
              <div style={callsLabelStyle}>Post Hooks Data</div>
            </div>

            <div style={scrollableCallsContentStyle}>
              <pre style={callsPreStyle}>
                Calculated gas limit (fill + calls): {callsModalData.gasLimit}
              </pre>
              <pre style={callsPreStyle}>
                <div style={callsInnerLabelStyle}>Calls</div>
                {JSON.stringify(callsModalData.calls, null, 2)}
              </pre>
              <pre style={callsPreStyle}>
                <div style={callsInnerLabelStyle}>Calldata</div>
                {JSON.stringify(callsModalData.callData, null, 2)}
              </pre>
            </div>
          </div>
        </div>
      )}

      <div style={tableWrapperStyle}>
        <div
          style={{
            ...notificationStyle,
            ...(notificationVisible ? showNotificationStyle : {}),
          }}
        >
          Copied to clipboard!
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              {columnsOrder.map((header, index) => {
                const columnKey = header.replace(/ /g, "").toLowerCase();
                return (
                  <th
                    key={index}
                    style={{
                      ...thStyle,
                      position: "relative",
                      display: visibleColumns[header] ? "table-cell" : "none",
                    }}
                    onDragStart={e => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={e => handleDrop(e, index)}
                    draggable
                    onMouseEnter={() => setHoverColumn(columnKey)}
                    onMouseLeave={() => setHoverColumn(null)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "50px",
                      }}
                    >
                      {header}
                      {[
                        "Order Hash",
                        "Order Refunded",
                        "Order Filled",
                        "Settlement Forwarded",
                        "Settlement Processed",
                        "Settlement Filled",
                        "Tokens Released",
                        "From Address",
                        "To Address",
                        "Filler",
                        "From Token",
                        "To Token",
                        "Expiry",
                        "From Amount",
                        "Fill Amount",
                        "Fee Rate",
                        "From Chain",
                        "To Chain",
                        "From Token USD",
                        "To Token USD",
                        "Fee Value",
                        "Post Hook Hash",
                        "Calls",
                      ].includes(header) && (
                        <div
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: hoverColumn === columnKey ? "flex" : "none",
                            gap: "1px",
                            background: "transparent",
                          }}
                        >
                          <SortButton columnKey={columnKey} />
                          <button
                            onClick={() =>
                              setVisibleColumns(prev => ({ ...prev, [header]: false }))
                            }
                            style={{
                              ...buttonStyle,
                              padding: "4px 8px",
                              margin: "-5px",
                              background: "transparent",
                            }}
                          >
                            🞨
                          </button>
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {paginatedSortedEvents.map((eventGroup, index) => (
              <tr
                key={index}
                style={{
                  ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                  ...(selectedOrders.some(
                    selected =>
                      selected.orderCreated?.orderHash === eventGroup.orderCreated?.orderHash,
                  )
                    ? selectedRowStyle
                    : {}),
                }}
                onMouseEnter={() => handleRowHover(eventGroup.orderCreated?.orderHash)}
                onMouseLeave={() => handleRowHover(null)}
                onClick={() => handleRowSelection(eventGroup)}
              >
                {columnsOrder.map(header => {
                  if (!visibleColumns[header]) return null;

                  const columnKey = header.replace(/ /g, "").toLowerCase();

                  switch (columnKey) {
                    case "type":
                      return (
                        <td key={columnKey} style={{ ...tdStyle, position: "relative" }}>
                          <div style={{ position: "relative" }}>
                            <a
                              href={getBlockExplorerUrl(
                                eventGroup.orderCreated?.data.order_fromChain,
                                eventGroup.transactionHashes.OrderCreated,
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={textLinkStyle}
                            >
                              {eventGroup.orderCreated?.type}
                            </a>
                            {showCopyButtonFor === eventGroup.orderCreated?.orderHash && (
                              <div style={copyButtonContainerStyle}>
                                <button
                                  style={copyButtonStyle}
                                  onClick={e => {
                                    e.stopPropagation();
                                    copyJson(eventGroup);
                                  }}
                                >
                                  📋
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      );

                    case "orderhash":
                      return <td style={tdStyle}>{eventGroup.orderCreated?.orderHash}</td>;

                    case "orderrefunded":
                      return (
                        <td
                          style={
                            eventGroup.statuses.OrderRefunded || eventGroup.statuses.TokensReleased
                              ? trueStatusStyle
                              : determineEligibilityForRefund(
                                    eventGroup.orderCreated?.data.order_expiry,
                                  ) &&
                                  chainId !== Number(eventGroup.orderCreated?.data.order_fromChain)
                                ? eligibleStatusStyle
                                : tdStyle
                          }
                        >
                          {eventGroup.statuses.OrderRefunded ? (
                            "true"
                          ) : eventGroup.statuses.TokensReleased ? (
                            "ineligible"
                          ) : chainId === Number(eventGroup.orderCreated?.data.order_fromChain) ? (
                            <ButtonFeedback
                              style={refundButtonStyle}
                              onClick={() =>
                                handleRefund(
                                  eventGroup.orderCreated?.orderHash,
                                  eventGroup.orderCreated?.data,
                                )
                              }
                            >
                              Refund
                            </ButtonFeedback>
                          ) : (
                            `eligible (${eventGroup.orderCreated?.data.order_fromChain})`
                          )}
                        </td>
                      );

                    case "orderfilled":
                      return (
                        <td
                          style={
                            eventGroup.statuses.OrderFilled
                              ? filledStatusStyle
                              : !eventGroup.statuses.OrderFilled &&
                                  !eventGroup.statuses.OrderRefunded
                                ? eligibleFillStatusStyle
                                : tdStyle
                          }
                        >
                          {eventGroup.statuses.OrderFilled ? (
                            <>
                              <span>true - </span>
                              <a
                                href={getBlockExplorerUrl(
                                  eventGroup.orderCreated?.data.order_toChain,
                                  eventGroup.transactionHashes.OrderFilled,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                view
                              </a>
                            </>
                          ) : !eventGroup.statuses.OrderRefunded &&
                            !eventGroup.statuses.OrderFilled &&
                            chainId === Number(eventGroup.orderCreated?.data.order_toChain) &&
                            walletAddress.toLowerCase() ===
                              eventGroup.orderCreated?.data.order_filler.toLowerCase() ? (
                            <>
                              <ButtonFeedback
                                style={fillButtonStyle}
                                onClick={() =>
                                  handleFill(
                                    eventGroup.orderCreated?.orderHash,
                                    eventGroup.orderCreated?.data,
                                  )
                                }
                              >
                                Fill
                              </ButtonFeedback>
                              <ButtonFeedback
                                style={approveButtonStyle}
                                onClick={() =>
                                  handleApprove(
                                    eventGroup.orderCreated?.orderHash,
                                    eventGroup.orderCreated?.data,
                                  )
                                }
                              >
                                Approve
                              </ButtonFeedback>
                            </>
                          ) : !eventGroup.statuses.OrderRefunded &&
                            !eventGroup.statuses.OrderFilled ? (
                            `eligible (${eventGroup.orderCreated?.data.order_toChain})`
                          ) : (
                            "ineligible"
                          )}
                        </td>
                      );

                    case "settlementforwarded":
                      return (
                        <td
                          style={
                            eventGroup.statuses.SettlementForwarded ? trueStatusStyle : tdStyle
                          }
                        >
                          {eventGroup.statuses.SettlementForwarded ? (
                            <>
                              <span>true - </span>
                              <a
                                href={getBlockExplorerUrl(
                                  eventGroup.orderCreated?.data.order_toChain,
                                  eventGroup.transactionHashes.SettlementForwarded,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                view
                              </a>
                              <span> - </span>
                              <a
                                href={getAxelarUrl(
                                  eventGroup.transactionHashes.SettlementForwarded,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                Axelar
                              </a>
                            </>
                          ) : (
                            "false"
                          )}
                        </td>
                      );

                    case "settlementprocessed":
                      return (
                        <td
                          style={
                            eventGroup.statuses.SettlementProcessed ? trueStatusStyle : tdStyle
                          }
                        >
                          {eventGroup.statuses.SettlementProcessed ? (
                            <>
                              <span>true - </span>
                              <a
                                href={getBlockExplorerUrl(
                                  hubChainId,
                                  eventGroup.transactionHashes.SettlementProcessed,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                view
                              </a>
                              <span> - </span>
                              <a
                                href={getAxelarUrl(
                                  eventGroup.transactionHashes.SettlementProcessed,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                Axelar
                              </a>
                            </>
                          ) : (
                            "false"
                          )}
                        </td>
                      );

                    case "settlementfilled":
                      return (
                        <td
                          style={eventGroup.statuses.SettlementFilled ? trueStatusStyle : tdStyle}
                        >
                          {eventGroup.statuses.SettlementFilled ? (
                            <>
                              <span>true - </span>
                              <a
                                href={getBlockExplorerUrl(
                                  hubChainId,
                                  eventGroup.transactionHashes.SettlementFilled,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                view
                              </a>
                              <span> - </span>
                              <a
                                href={getAxelarUrl(eventGroup.transactionHashes.SettlementFilled)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                Axelar
                              </a>
                            </>
                          ) : (
                            "false"
                          )}
                        </td>
                      );

                    case "tokensreleased":
                      return (
                        <td style={eventGroup.statuses.TokensReleased ? trueStatusStyle : tdStyle}>
                          {eventGroup.statuses.TokensReleased ? (
                            <>
                              <span>true - </span>
                              <a
                                href={getBlockExplorerUrl(
                                  eventGroup.orderCreated?.data.order_fromChain,
                                  eventGroup.transactionHashes.TokensReleased,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                view
                              </a>
                              <span> - </span>
                              <a
                                href={getAxelarUrl(eventGroup.transactionHashes.TokensReleased)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textLinkStyle}
                              >
                                Axelar
                              </a>
                            </>
                          ) : (
                            "false"
                          )}
                        </td>
                      );

                    case "fromaddress":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_fromAddress}</td>
                      );

                    case "toaddress":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_toAddress}</td>
                      );

                    case "filler":
                      return <td style={tdStyle}>{eventGroup.orderCreated?.data.order_filler}</td>;

                    case "fromtoken":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_fromToken}</td>
                      );

                    case "totoken":
                      return <td style={tdStyle}>{eventGroup.orderCreated?.data.order_toToken}</td>;

                    case "expiry":
                      return (
                        <td style={expiryColumnStyle}>
                          {eventGroup.orderCreated?.data.order_expiry} (
                          {convertUnixToDate(eventGroup.orderCreated?.data.order_expiry || "")})
                        </td>
                      );

                    case "fromamount":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_fromAmount}</td>
                      );

                    case "fillamount":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_fillAmount}</td>
                      );

                    case "feerate":
                      return <td style={tdStyle}>{eventGroup.orderCreated?.data.order_feeRate}</td>;

                    case "fromchain":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_fromChain}</td>
                      );

                    case "tochain":
                      return <td style={tdStyle}>{eventGroup.orderCreated?.data.order_toChain}</td>;

                    case "fromtokenusd":
                      return <td style={tdStyle}>{eventGroup.usdValueFromToken}</td>;

                    case "totokenusd":
                      return <td style={tdStyle}>{eventGroup.usdValueToToken}</td>;

                    case "feevalue":
                      return <td style={tdStyle}>{eventGroup.feeValue}</td>;

                    case "posthookhash":
                      return (
                        <td style={tdStyle}>{eventGroup.orderCreated?.data.order_postHookHash}</td>
                      );

                    case "calls":
                      const postHookHash = eventGroup.orderCreated?.data.order_postHookHash;
                      if (postHookHash !== encodeBytes32String("")) {
                        return (
                          <td style={tdStyle}>
                            <ButtonFeedback
                              style={callsButtonStyle}
                              onClick={() =>
                                handleViewCalls(eventGroup.orderCreated?.orderHash, postHookHash)
                              }
                            >
                              View Calls
                            </ButtonFeedback>
                          </td>
                        );
                      }
                      return <td style={tdStyle}></td>;

                    default:
                      return null;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={controlContainerStyle} className="tw-overflow-auto">
        <ul style={paginationStyle}>
          <li>
            <span
              onClick={() => handlePageChange(1)}
              style={currentPage === 1 ? pageLinkStyle : activePageLinkStyle}
            >
              {"<<"}
            </span>
          </li>
          <li>
            <span
              onClick={() => handlePageChange(currentPage - 1)}
              style={currentPage === 1 ? pageLinkStyle : activePageLinkStyle}
            >
              {"<"}
            </span>
          </li>
          {[...Array(totalPages)].map((_, i) => (
            <li key={i}>
              <span
                onClick={() => handlePageChange(i + 1)}
                style={currentPage === i + 1 ? activePageLinkStyle : pageLinkStyle}
              >
                {i + 1}
              </span>
            </li>
          ))}
          <li>
            <span
              onClick={() => handlePageChange(currentPage + 1)}
              style={currentPage === totalPages ? pageLinkStyle : activePageLinkStyle}
            >
              {">"}
            </span>
          </li>
          <li>
            <span
              onClick={() => handlePageChange(totalPages)}
              style={currentPage === totalPages ? pageLinkStyle : activePageLinkStyle}
            >
              {">>"}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RFQ;
