import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { SquidLogo } from "./icons/SquidLogo";
import { HeadingText, Input } from "@0xsquid/ui";

interface OrdersPageHeaderProps {
  hashInput: string;
  setHashInput: React.Dispatch<React.SetStateAction<string>>;
  handleSearchSubmit?: (event: React.FormEvent) => void;
}

export function OrdersPageHeader({
  hashInput,
  setHashInput,
  handleSearchSubmit,
}: OrdersPageHeaderProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const pasteFromClipboard = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    if (text) {
      setHashInput(text);
    }
  }, []);

  return (
    <nav className="tw-flex tw-pt-squid-xs tw-pb-squid-xl tw-justify-center tw-px-squid-[55px] tw-w-full tw-items-center tw-gap-squid-xs tw-relative">
      <div className="tw-absolute tw-top-squid-xs tw-left-0">
        <Link
          to="/"
          className="tw-flex tw-h-squid-xl tw-items-center tw-gap-squid-xs tw-justify-self-center"
        >
          <SquidLogo size={40} />

          <HeadingText size="small" className="tw-flex tw-items-center">
            Coralscan
          </HeadingText>
        </Link>
      </div>

      <form className="tw-max-w-[600px] tw-flex-1 tw-bg-grey-900" onSubmit={handleSearchSubmit}>
        <Input
          inputRef={inputRef}
          type="text"
          onChange={e => setHashInput(e.target.value)}
          placeholder="Search by tx hash, order hash, or address"
          value={hashInput}
          actionButtonProps={{
            label: "Paste",
            onClick: pasteFromClipboard,
          }}
        />
      </form>
    </nav>
  );
}
