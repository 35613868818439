import { CSSProperties } from "react";

export const containerStyle: CSSProperties = {
  width: "100%",
  padding: "20px 0px 20px 20px",
  boxSizing: "border-box",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
};

export const mainContainerStyle: CSSProperties = {
  display: "flex",
  alignItems: "flex-start",
  gap: "20px",
  padding: "20px 20px 0px 20px",
};

export const filterContainerStyle: CSSProperties = {
  width: "70%",
  boxSizing: "border-box",
};

export const analyticsContainerStyle: CSSProperties = {
  width: "50%",
  maxWidth: "50%",
  height: "100%",
  border: "1px solid #ddd",
  borderRadius: "8px",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#999",
};

export const inputStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px",
  width: "200px",
  border: "1px solid #ddd",
  borderRadius: "4px",
};

export const selectStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px",
  border: "1px solid #ddd",
  borderRadius: "4px",
};

export const defaultButtonStyle: CSSProperties = {
  transition: "transform 0.2s, background-color 0.2s",
};

export const buttonStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
};

export const connectButtonStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
};

export const disconnectButtonStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-highlight-700)",
  color: "black",
  cursor: "pointer",
  fontSize: "14px",
};

export const refundButtonStyle: CSSProperties = {
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
};

export const resetButtonStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
  flexShrink: "0",
  minWidth: "75px",
};

export const exportButtonStyle: CSSProperties = {
  margin: "10px 5px",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
  flexShrink: "0",
};

export const tableWrapperStyle: CSSProperties = {
  overflowX: "auto",
  marginTop: "20px",
  width: "100%",
  overflow: "auto",
};

export const tableStyle: CSSProperties = {
  width: "100%",
  minWidth: "1000px",
  borderCollapse: "collapse",
  tableLayout: "auto",
};

export const thStyle: CSSProperties = {
  border: "1px solid #ddd",
  padding: "12px 32px 12px 12px",
  textAlign: "left",
  backgroundColor: "var(--st-color-royal-500)",
  color: "var(--st-color-grey-100)",
  fontWeight: "bold",
  whiteSpace: "nowrap",
};

export const tdStyle: CSSProperties = {
  border: "1px solid #ddd",
  padding: "12px 32px 12px 12px",
  backgroundColor: "var(--st-color-grey-900)",
  color: "var(--st-color-grey-100)",
  textAlign: "left",
  whiteSpace: "nowrap",
};

export const trueStatusStyle: CSSProperties = {
  ...tdStyle,
  backgroundColor: "var(--st-color-royal-500)",
};

export const eligibleStatusStyle: CSSProperties = {
  ...tdStyle,
  backgroundColor: "var(--st-color-highlight-700)",
  color: "black",
};

export const oddRowStyle: CSSProperties = {
  // backgroundColor: "#ffffff",
};

export const evenRowStyle: CSSProperties = {
  // backgroundColor: "#eeeeee",
};

export const expiryColumnStyle: CSSProperties = {
  ...tdStyle,
  minWidth: "220px",
};

export const headerStyle: CSSProperties = {
  textAlign: "center",
  marginBottom: "20px",
  fontSize: "28px",
  color: "#333",
};

export const controlContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  margin: "10px 0",
  flexWrap: "nowrap",
};

export const paginationStyle: CSSProperties = {
  display: "flex",
  listStyleType: "none",
  padding: 0,
  gap: "8px",
  marginTop: "10px",
};

export const pageLinkStyle: CSSProperties = {
  padding: "4px 8px",
  color: "#007bff",
  textDecoration: "none",
  cursor: "pointer",
  fontSize: "14px",
};

export const activePageLinkStyle: CSSProperties = {
  ...pageLinkStyle,
  fontWeight: "bold",
  color: "#333",
  textDecoration: "underline",
};

export const infoStyle: CSSProperties = {
  padding: "10px",
  backgroundColor: "var(--st-color-royal-500)",
  borderRadius: "4px",
  fontSize: "14px",
  marginBottom: "10px",
};

export const batchInfoContainerStyle: CSSProperties = {
  backgroundColor: "var(--st-color-royal-500)",
  borderRadius: "4px",
  padding: "10px",
  fontSize: "14px",
  flexGrow: 1,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

export const filterInputStyle: CSSProperties = {
  listStyleType: "none",
  padding: 0,
  margin: 0,
  position: "absolute",
  zIndex: 1,
  border: "1px solid #ddd",
  borderRadius: "4px",
  maxHeight: "150px",
  overflowY: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  minWidth: "200px",
  width: "auto",
  paddingLeft: "10px",
  paddingRight: "10px",
};

export const listItemStyle: CSSProperties = {
  padding: "8px",
  cursor: "pointer",
  whiteSpace: "normal",
  overflow: "visible",
  textOverflow: "clip",
};

export const selectedRowStyle: CSSProperties = {
  backgroundColor: "rgba(0, 255, 0, 0.2)",
};

export const batchControlContainerStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "10px",
  marginBottom: "10px",
};

export const batchValueInputStyle: CSSProperties = {
  width: "80px",
  padding: "4px",
  borderRadius: "4px",
  border: "1px solid #ddd",
};

export const batchGasLimitInputStyle: CSSProperties = {
  width: "80px",
  padding: "4px",
  borderRadius: "4px",
  border: "1px solid #ddd",
};

export const batchFillerAddressInputStyle: CSSProperties = {
  width: "180px",
  padding: "4px",
  borderRadius: "4px",
  border: "1px solid #ddd",
};

export const batchChainIdInputStyle: CSSProperties = {
  width: "70px",
  padding: "4px",
  borderRadius: "4px",
  border: "1px solid #ddd",
};

export const fillButtonStyle: CSSProperties = {
  width: "40px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
  marginRight: "5px",
};

export const filledStatusStyle: CSSProperties = {
  ...tdStyle,
  backgroundColor: "var(--st-color-royal-500)",
};

export const eligibleFillStatusStyle: CSSProperties = {
  ...tdStyle,
  backgroundColor: "var(--st-color-highlight-700)",
  color: "black",
};

export const approveButtonStyle: CSSProperties = {
  width: "80px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
};

export const textLinkStyle: CSSProperties = {
  color: "rgb(85 85 189)",
  textDecoration: "underline",
  cursor: "pointer",
};

export const dateRangeStyle: CSSProperties = {
  position: "absolute",
  listStyleType: "none",
  padding: "0",
  margin: "0",
  border: "1px solid #ddd",
  borderRadius: "4px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
  width: "150px",
};

export const callsPreStyle: CSSProperties = {
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  fontFamily: "monospace",
  padding: "10px 50px 10px 10px",
  color: "#333",
  borderRadius: "5px",
  width: "100%",
  marginBottom: "10px",
};

export const callsModalBackgroundStyle: CSSProperties = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

export const callsModalContentStyle: CSSProperties = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  position: "relative",
  minWidth: "300px",
  maxWidth: "600px",
  height: "80vh",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

export const scrollableCallsContentStyle: CSSProperties = {
  flexGrow: 1,
  overflowY: "auto",
  overflowX: "hidden",
  padding: "10px 25px 0 0",
};

export const closeCallsButtonStyle: CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  border: "none",
  background: "transparent",
  fontSize: "24px",
  cursor: "pointer",
};

export const callsButtonStyle: CSSProperties = {
  width: "80px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "var(--st-color-royal-500)",
  cursor: "pointer",
  fontSize: "14px",
};

export const callsLabelStyle: CSSProperties = {
  backgroundColor: "var(--st-color-royal-500)",
  padding: "10px",
  fontWeight: "bold",
  textAlign: "center",
};

export const callsInnerLabelStyle: CSSProperties = {
  backgroundColor: "transparent",
  padding: "10px",
  fontWeight: "bold",
  textAlign: "center",
};

export const callsContentStyle: CSSProperties = {
  backgroundColor: "white",
  padding: "10px",
  textAlign: "left",
  wordBreak: "break-all",
  borderBottom: "1px solid #ddd",
};

export const callsDetailsStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  marginBottom: "20px",
  fontSize: "14px",
  color: "#333",
};

export const copyButtonContainerStyle: CSSProperties = {
  position: "absolute",
  right: "-30px",
  top: "50%",
  transform: "translateY(-50%)",
  display: "inline",
};
export const copyButtonStyle: CSSProperties = {
  border: "none",
  background: "transparent",
  cursor: "pointer",
  fontSize: "16px",
};
export const notificationStyle: CSSProperties = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "var(--st-color-royal-500)",
  color: "#333",
  padding: "10px 20px",
  borderRadius: "5px",
  boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
  transition: "opacity 0.5s ease-in-out",
  opacity: 0,
  zIndex: 1001,
};
export const showNotificationStyle: CSSProperties = {
  opacity: 1,
};

// tx explorer

export const txContainerStyle: CSSProperties = {
  width: "100%",
  padding: "20px",
  boxSizing: "border-box",
  backgroundColor: "var(--bg-color)", // Dark: #1b212b
};

export const txHeaderStyle: CSSProperties = {
  textAlign: "center",
  marginBottom: "20px",
  fontSize: "28px",
  color: "var(--text-color)", // Dark: #fff
};

export const lineWrapperStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50%",
  margin: "0 auto",
};

export const settlementLineWrapperStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "70%",
  margin: "0 auto",
};

export const lineStyle = (isCompleted: boolean): CSSProperties => ({
  flexGrow: 1,
  height: isCompleted ? "8px" : "4px",
  backgroundColor: isCompleted ? "var(--accent-color)" : "#f0f0f0", // Dark: #e3f734 : #fff
  margin: "0",
});

export const circleContainerStyle: CSSProperties = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const sourceTokenCircleContainerStyle: CSSProperties = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginRight: "10px",
};

export const destinationTokenCircleContainerStyle: CSSProperties = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "10px",
};

export const textContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "70%",
  margin: "10px auto",
};

export const txSummaryLinkStyle: CSSProperties = {
  color: "var(--link-color)", // Dark: white
  textDecoration: "underline",
  cursor: "pointer",
  fontSize: "16px",
};

export const txTextLinkStyle: CSSProperties = {
  position: "absolute",
  bottom: "-20px",
  color: "var(--link-color)", // Dark: white
  textDecoration: "none",
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
};

export const settlementTxTextLinkStyle: CSSProperties = {
  position: "absolute",
  bottom: "-40px",
  color: "var(--link-color)", // Dark: white
  textDecoration: "none",
  cursor: "pointer",
  fontSize: "14px",
  textAlign: "center",
};

export const pendingTextStyle: CSSProperties = {
  position: "absolute",
  bottom: "-40px",
  color: "var(--text-color)", // Dark: #fff
  fontSize: "14px",
  textAlign: "center",
};

export const settlementPendingTextStyle: CSSProperties = {
  position: "absolute",
  bottom: "-60px",
  color: "var(--text-color)", // Dark: #fff
  fontSize: "14px",
  textAlign: "center",
};

export const centerTextStyle: CSSProperties = {
  marginBottom: "50px",
  textAlign: "center",
  color: "var(--text-color)", // Dark: #fff
  fontSize: "16px",
};

export const detailsContainerStyle: CSSProperties = {
  margin: "50px auto",
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "var(--shadow-color)", // Dark: #13171f
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  width: "75%",
};

export const dataListStyle: CSSProperties = {
  listStyleType: "none",
  padding: 0,
  margin: 0,
};

export const timeTextContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "70%",
  margin: "10px auto 0",
  color: "var(--time-color)", // Dark: #fff
  fontSize: "14px",
};

export const imageStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "50%",
};

export const axelarLinkStyle: CSSProperties = {
  display: "block",
  marginTop: "5px",
  color: "var(--link-color)", // Dark: white
  textDecoration: "none",
  fontSize: "12px",
  textAlign: "center",
  cursor: "pointer",
};

export const orderDataContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export const orderDataItemStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "10px 5px",
  borderRadius: "8px",
  backgroundColor: "var(--bg-color)", // Dark: #1b212b
  color: "var(--text-color)", // Dark: #fff
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
};

export const orderDataLabelStyle: CSSProperties = {
  fontWeight: "bold",
  minWidth: "250px",
  whiteSpace: "nowrap",
  marginLeft: "20px",
};

export const orderDataValueStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  flexGrow: 1,
};

export const brandingContainerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const headerContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
  width: "80%",
  margin: "0 auto",
};

export const iconStyle: React.CSSProperties = {
  width: "25px",
  height: "25px",
  marginRight: "10px",
  borderRadius: "50%",
};

export const searchBoxStyle: React.CSSProperties = {
  width: "600px",
  padding: "10px 40px 10px 10px",
  borderRadius: "8px",
  border: "1px solid #ccc",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  fontSize: "16px",
};

export const spinnerContainerStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--bg-color)",
  zIndex: 999,
};

export const spinnerStyle: React.CSSProperties = {
  border: "8px solid #f3f3f3",
  borderTop: "8px solid var(--accent-color)",
  borderRadius: "50%",
  width: "60px",
  height: "60px",
  animation: "spin 1s linear infinite",
};

export const searchInputContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "600px",
  position: "relative",
};

export const tokenSearchInputContainerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  position: "relative",
};

export const fullHeightStyle: React.CSSProperties = {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "var(--bg-color)",
};

export const searchIconStyle: React.CSSProperties = {
  position: "absolute",
  right: "10px",
  color: "#888", // Dark: #fff
  fontSize: "20px",
};

export const copyIconStyle: React.CSSProperties = {
  color: "var(--st-color-royal-500)",
};

export const tooltipStyle: CSSProperties = {
  position: "absolute",
  backgroundColor: "var(--text-color)", // Dark: #fff
  color: "var(--text2-color)", // Dark: #333
  padding: "5px",
  borderRadius: "4px",
  fontSize: "12px",
  zIndex: 1000,
  pointerEvents: "none",
  transform: "translate(-30%, -110%)",
  whiteSpace: "nowrap",
};

export const topBannerStyle: React.CSSProperties = {
  backgroundColor: "var(--accent2-color)", // Dark: #d5bef4
  color: "#333", // Dark: #fff
  textAlign: "center",
  padding: "10px 0",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1000,
  fontSize: "16px",
};

export const coralScanBannerStyle: React.CSSProperties = {
  color: "var(--accent-color)", // Dark: #fff
  marginRight: "8px",
  fontSize: "16px",
  textDecoration: "none",
};

export const nightToggleStyle: React.CSSProperties = {
  padding: "10px 0",
  position: "absolute",
  top: -3,
  right: 20,
  zIndex: 1001,
};

export const latestOrdersDataContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export const latestOrdersItemStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "10px 5px",
  borderRadius: "8px",
  backgroundColor: "var(--bg-color)", // Dark: #1b212b
  color: "var(--text-color)", // Dark: #fff
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  margin: "8px 0 8px 0",
};

export const tokensItemStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "10px 5px",
  borderRadius: "8px",
  backgroundColor: "var(--bg-color)", // Dark: #1b212b
  color: "var(--text-color)", // Dark: #fff
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  margin: "4px 0 4px 0",
};

export const latestOrdersDataValueStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  flexGrow: 1,
  margin: "0 0 0 10px",
};

export const circleImageStyle: CSSProperties = {
  width: 24,
  height: 24,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "8px",
};

export const loadMoreButtonStyle: CSSProperties = {
  display: "block",
  margin: "20px auto",
  padding: "10px 20px",
  fontSize: "18px",
  fontWeight: "bold",
  borderRadius: "8px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "var(--accent-color)",
  color: "var(--text2-color)",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transition: "background-color 0.3s",
};

export const noOrdersFoundStyle: CSSProperties = {
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "24px",
  color: "var(--text-color)",
  padding: "50px",
};

export const authenticatorContainerStyle: React.CSSProperties = {
  width: "100%",
  padding: "20px",
  boxSizing: "border-box",
  backgroundColor: "var(--bg-color)",
  color: "var(--text-color)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
};

export const tokensContainerStyle: CSSProperties = {
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "var(--shadow-color)",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  maxHeight: "30vh",
  scrollbarWidth: "thin",
  scrollbarColor: "var(--accent-color) transparent",
  ...{
    "::WebkitScrollbar": {
      width: "6px",
      height: "6px",
    },
    "::WebkitScrollbarTrack": {
      background: "transparent",
    },
    "::WebkitScrollbarThumb": {
      background: "var(--accent-color)",
      borderRadius: "10px",
    },
  },
};

export const tokensDataContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

export const tokenFilterContainerStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "10px",
  backgroundColor: "var(--shadow-color)",
  borderBottom: "1px solid var(--accent-color)",
  borderRadius: "8px",
};

export const tokenContainerWrapperStyle: CSSProperties = {
  width: "60%",
  margin: "20px auto",
  borderBottom: "1px solid var(--accent-color)",
  borderRadius: "8px",
  backgroundColor: "var(--shadow-color)",
  scrollbarWidth: "thin",
  scrollbarColor: "var(--accent-color) transparent",
  ...{
    "::WebkitScrollbar": {
      width: "6px",
      height: "6px",
    },
    "::WebkitScrollbarTrack": {
      background: "transparent",
    },
    "::WebkitScrollbarThumb": {
      background: "var(--accent-color)",
      borderRadius: "10px",
    },
  },
};

export const tokenButtonStyle: CSSProperties = {
  padding: "8px 16px",
  fontSize: "14px",
  borderRadius: "8px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "var(--accent-color)",
  color: "var(--text2-color)",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  transition: "background-color 0.3s",
  margin: "0 10px",
};

export const chainDropdownStyle: CSSProperties = {
  position: "absolute",
  padding: "10px",
  backgroundColor: "var(--bg-color)",
  border: "1px solid var(--accent-color)",
  color: "var(--text-color)",
  borderRadius: "8px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
  top: "100%",
  zIndex: 1000,
};

export const chainCheckboxContainerStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  margin: "5px 0",
};

export const tokenIconStyle: React.CSSProperties = {
  width: "20px",
  height: "20px",
  marginRight: "10px",
  borderRadius: "50%",
  color: "var(--accent-color)",
};

export const environmentsDropdownStyle: CSSProperties = {
  position: "absolute",
  padding: "10px",
  backgroundColor: "var(--bg-color)",
  border: "1px solid var(--accent-color)",
  color: "var(--text-color)",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
  bottom: "100%",
  zIndex: 1000,
  maxHeight: "290px",
  overflowY: "auto",
  width: "150px",
  marginLeft: "295px",
  marginBottom: "10px",
  scrollbarWidth: "thin",
  scrollbarColor: "var(--accent-color) transparent",
  ...{
    "::WebkitScrollbar": {
      width: "6px",
      height: "6px",
    },
    "::WebkitScrollbarTrack": {
      background: "transparent",
    },
    "::WebkitScrollbarThumb": {
      background: "var(--accent-color)",
      borderRadius: "10px",
    },
  },
};

export const environmentsCheckboxContainerStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  margin: "5px 0",
};

export const environmentContainerStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "10px",
};

export const resultsContainerStyle: React.CSSProperties = {
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "var(--shadow-color)",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
};

export const resultsHeaderStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "bold",
  borderBottom: "1px solid var(--accent-color)",
  paddingBottom: "8px",
  marginBottom: "8px",
};

export const resultRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 0",
  borderBottom: "1px solid var(--accent-color)",
};

export const pairInfoStyle: React.CSSProperties = {
  display: "flex",
  flex: 1,
  justifyContent: "space-around",
};

export const environmentResultsStyle: React.CSSProperties = {
  flex: "70%",
  paddingLeft: "10px",
  display: "flex",
  flexDirection: "column",
};

export const benchmarkRouteInfoStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: "30%",
};

export const benchmarkSegmentStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const resultsContainerWrapperStyle: CSSProperties = {
  width: "60%",
  margin: "20px auto",
  borderBottom: "1px solid var(--accent-color)",
  borderRadius: "8px",
  backgroundColor: "var(--shadow-color)",
  scrollbarWidth: "thin",
  padding: "10px",
  overflowY: "auto",
  scrollbarColor: "var(--accent-color) transparent",
  ...{
    "::WebkitScrollbar": {
      width: "6px",
      height: "6px",
    },
    "::WebkitScrollbarTrack": {
      background: "transparent",
    },
    "::WebkitScrollbarThumb": {
      background: "var(--accent-color)",
      borderRadius: "10px",
    },
  },
};

export const benchmarkCircleImageStyle: CSSProperties = {
  width: 24,
  height: 24,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const benchmarkResultRowStyle: CSSProperties = {
  transition: "background-color 0.3s ease",
};

export const benchmarkResultTransitionStyle: CSSProperties = {
  transition: "max-height 0.5s ease, opacity 0.5s ease",
  overflow: "hidden",
  margin: "0",
};

export const benchmarkResultExpandedStyle: React.CSSProperties = {
  backgroundColor: "var(--shadow-color)",
  borderRadius: "8px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
  color: "var(--text-color)",
};

export const progressBarContainerStyle: CSSProperties = {
  width: "100%",
  height: "8px",
  borderRadius: "4px",
  border: "1px solid var(--accent-color)",
  overflow: "hidden",
};

export const progressStyle: CSSProperties = {
  height: "100%",
  transition: "width 0.3s ease",
  backgroundColor: "var(--accent-color)",
};

export const progressLabelStyle: CSSProperties = {
  position: "absolute",
  top: "-18px",
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: "10px",
  color: "var(--text-color)",
};

export const progressContainerWrapperStyle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  margin: "10px 0 0 0",
};

export const environmentButtonStyle: CSSProperties = {
  padding: "8px 16px",
  fontSize: "14px",
  borderRadius: "8px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "var(--accent-color)",
  color: "var(--text2-color)",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  transition: "background-color 0.3s",
};
