import React from "react";

interface OrderInfoSectionProps {
  children: React.ReactNode;
}
export function OrderInfoSection({ children }: OrderInfoSectionProps) {
  return (
    <article className="tw-flex tw-py-squid-l tw-px-squid-s mobile-lg:tw-px-squid-xl tw-flex-col tw-justify-center tw-items-start tw-self-stretch tw-rounded-squid-m tw-border tw-border-material-light-thin tw-overflow-auto">
      {children}
    </article>
  );
}
