import React from "react";
import { notificationStyle, showNotificationStyle } from "src/styles/styles";

export function Notification({ message, visible }: { visible: boolean; message: string }) {
  return (
    <div
      style={{
        ...notificationStyle,
        ...(visible ? showNotificationStyle : {}),
      }}
    >
      {message}
    </div>
  );
}
