import React from "react";
import { LeftBlob, MiddleBlob, RightBlob } from "./BlobSvgs";
import { CaptionText, cn } from "@0xsquid/ui";

type BlobDirection = "left" | "right" | "middle";

const blobDirectionMap: Record<BlobDirection, React.ReactNode> = {
  left: <LeftBlob />,
  right: <RightBlob />,
  middle: <MiddleBlob />,
};

const blobDirectionClassNameMap: Record<BlobDirection, string> = {
  left: "tw-justify-start tw-pl-squid-xs",
  right: "tw-justify-end tw-pr-squid-xs",
  middle: "tw-justify-center !tw-min-w-[104px]",
};

const subtitleClassNameMap: Partial<Record<BlobDirection, string>> = {
  left: "tw-left-squid-xs tw-text-left",
  right: "tw-right-squid-xs tw-text-right",
  middle: "tw-text-center",
};

interface BlobImageProps {
  direction: BlobDirection;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  isPending?: boolean;
}

export function BlobImage({ direction, children, subtitle, isPending }: BlobImageProps) {
  return (
    <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-relative">
      <div
        className={cn(
          "tw-min-w-[92px] tw-h-[80px] tw-relative tw-flex tw-items-center",
          blobDirectionClassNameMap[direction],
        )}
      >
        {blobDirectionMap[direction]}

        {children}
      </div>

      <CaptionText
        className={cn(
          "tw-self-center tw-absolute tw-top-[85px]",
          subtitleClassNameMap[direction],
          isPending ? "tw-text-grey-600" : "tw-text-grey-300",
        )}
      >
        {subtitle}
      </CaptionText>
    </div>
  );
}
