import React from "react";

export function LeftBlob() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="80"
      viewBox="0 0 92 80"
      fill="none"
      className="tw-shrink-0 tw-absolute tw-inset-0"
    >
      <path
        d="M0 40C0 29.7631 3.90524 19.5262 11.7157 11.7157C19.5262 3.90529 29.763 4.41404e-05 39.9998 0C50.2368 -4.41381e-05 60.4737 3.9052 68.2843 11.7157C69.3391 12.7705 70.3756 13.8374 71.4021 14.8938L71.4022 14.8939C77.9776 21.6613 84.1366 28 92 28H93V52H92C84.1311 52 77.9652 58.3479 71.3869 65.1204C70.3653 66.1722 69.3337 67.2342 68.2843 68.2843C60.4737 76.0948 50.2368 80 39.9998 80C29.763 80 19.5262 76.0947 11.7157 68.2843C3.90524 60.4738 0 50.2369 0 40Z"
        fill="var(--st-color-royal-800)"
      />
    </svg>
  );
}

export function RightBlob() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="80"
      viewBox="0 0 92 80"
      fill="none"
      className="tw-shrink-0 tw-absolute tw-inset-0"
    >
      <path
        d="M92 40C92 29.7631 88.0948 19.5262 80.2843 11.7157C72.4738 3.90529 62.237 4.41404e-05 52.0002 0C41.7632 -4.41381e-05 31.5263 3.9052 23.7157 11.7157C22.6609 12.7705 21.6244 13.8374 20.5979 14.8938L20.5978 14.8939C14.0224 21.6613 7.86337 28 0 28V52C7.86885 52 14.0348 58.3479 20.6131 65.1204C21.6347 66.1722 22.6663 67.2342 23.7157 68.2843C31.5263 76.0948 41.7632 80 52.0002 80C62.237 80 72.4738 76.0947 80.2843 68.2843C88.0948 60.4738 92 50.2369 92 40Z"
        fill="var(--st-color-royal-800)"
      />
    </svg>
  );
}

export function MiddleBlob() {
  return (
    <div className="tw-grid tw-grid-cols-2 tw-items-center tw-justify-center tw-shrink-0 tw-absolute tw-inset-0 tw-w-[104px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="80"
        viewBox="0 0 52 80"
        fill="none"
      >
        <path
          d="M52 0C41.7631 -4.41381e-05 31.5261 3.9052 23.7156 11.7157C22.6608 12.7705 21.6242 13.8374 20.5977 14.8938L20.5976 14.8939C14.0222 21.6613 7.86319 28 -0.000179291 28V52C7.86867 52 14.0346 58.3479 20.6129 65.1204C21.6345 66.1722 22.6661 67.2342 23.7156 68.2843C31.5261 76.0948 41.7631 80 52 80V0Z"
          fill="var(--st-color-royal-800)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="80"
        viewBox="0 0 52 80"
        fill="none"
      >
        <path
          d="M0 0C10.2369 -4.41381e-05 20.4739 3.9052 28.2844 11.7157C29.3392 12.7705 30.3758 13.8374 31.4023 14.8938L31.4024 14.8939C37.9778 21.6613 44.1368 28 52.0002 28V52C44.1313 52 37.9654 58.3479 31.3871 65.1204C30.3655 66.1722 29.3339 67.2342 28.2844 68.2843C20.4739 76.0948 10.2369 80 0 80V0Z"
          fill="var(--st-color-royal-800)"
        />
      </svg>
    </div>
  );
}
