import { formatUnits } from "ethers";
import { FixedNumber } from "ethers";

export const calculateElapsedTime = (startTimestamp?: number, endTimestamp?: number) => {
  if (startTimestamp !== undefined && endTimestamp !== undefined) {
    return formatElapsedTime(endTimestamp - startTimestamp);
  }
  return "N/A";
};

export const calculateRawExchangeRate = (fromAmount: string, fillAmount: string): number => {
  const fromAmountBigInt = BigInt(fromAmount);
  const fillAmountBigInt = BigInt(fillAmount);
  return Number(fillAmountBigInt) / Number(fromAmountBigInt);
};

export const calculateFinalExchangeRate = (
  fromAmount: string,
  fillAmount: string,
  feeRate: string,
): number => {
  const sourceTotal = calculateSourceTotal(fromAmount, feeRate);
  const fillAmountBigInt = BigInt(fillAmount);
  return Number(fillAmountBigInt) / Number(sourceTotal);
};

export const calculateSourceTotal = (fromAmount: string, feeRate: string): bigint => {
  const fromAmountBigInt = BigInt(fromAmount);
  const feeRateBigInt = BigInt(feeRate);
  return fromAmountBigInt - (fromAmountBigInt * feeRateBigInt) / BigInt(1000000);
};

export const calculateFeeTotal = (fromAmount: string, feeRate: string): bigint => {
  const fromAmountBigInt = BigInt(fromAmount);
  const feeRateBigInt = BigInt(feeRate);
  return (fromAmountBigInt * feeRateBigInt) / BigInt(1000000);
};

export const calculateTokenAmount = (
  usdPrice: string,
  decimals: string,
  targetUSD: number = 100,
): string => {
  const pricePerToken = parseFloat(usdPrice);
  const tokenDecimals = parseInt(decimals, 10);

  const factor = Math.pow(10, tokenDecimals);
  const amount = (targetUSD / pricePerToken) * factor;

  return Math.round(amount).toString();
};

export const convertUnixToDate = (unixTimestamp: string): string => {
  const date = new Date(parseInt(unixTimestamp) * 1000);
  return date.toLocaleString();
};

export const computeFee = (fromAmount: string, feeRate: string): string => {
  const amount = BigInt(fromAmount);
  const fee = (amount * BigInt(feeRate)) / BigInt(1000000);
  return fee.toString();
};

export const computeTokenUsdPrice = (
  amount: bigint,
  decimals: number,
  usdPrice: number,
  precision: number = 3,
): string => {
  const formattedAmount = formatUnits(amount, decimals);

  const fixedNumberAmount = FixedNumber.fromString(formattedAmount, "fixed128x18");
  const fixedNumberPrice = FixedNumber.fromString(usdPrice.toString(), "fixed128x18");

  const fixedUsdValue = fixedNumberAmount.mulUnsafe(fixedNumberPrice);

  return parseFloat(fixedUsdValue.toString()).toFixed(precision);
};

export const formatDate = (timestamp?: number): string => {
  return timestamp ? new Date(timestamp * 1000).toLocaleString() : "N/A";
};

export const formatDateTime = (timestamp?: number): string => {
  if (!timestamp) return "N/A";

  const date = new Date(timestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatDateForFilename = (): string => {
  const date = new Date();
  return date.toISOString().replace(/[-:]/g, "").replace("T", "_").slice(0, 15);
};

export const formatElapsedTime = (seconds: number): string => {
  const d = Math.floor(seconds / (24 * 3600));
  const h = Math.floor((seconds % (24 * 3600)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  return [d > 0 ? `${d}d` : "", h > 0 ? `${h}h` : "", m > 0 ? `${m}m` : "", s > 0 ? `${s}s` : ""]
    .filter(Boolean)
    .join(" ");
};

export const formatTxHash = (hash: string): string => {
  if (!hash) return "";
  return `${hash.slice(0, 6)}...${hash.slice(-4)}`;
};

export const formatAddress = (address: string): string => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const formatTokenAmount = (amount: string | bigint, decimals: string): string => {
  try {
    const amountBigInt = BigInt(amount);
    const decimalPlaces = parseInt(decimals, 10);
    return parseFloat(formatUnits(amountBigInt, decimalPlaces)).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimalPlaces,
    });
  } catch (err) {
    console.error("Error formatting token amount:", err);
    return "N/A";
  }
};

export const calculateUSDForToAmount = (
  toAmount: string,
  usdPrice: string,
  decimals: string,
): string => {
  const toAmountBigInt = BigInt(toAmount);
  const pricePerToken = parseFloat(usdPrice);
  const tokenDecimals = parseInt(decimals, 10);

  const formattedAmount = formatUnits(toAmountBigInt, tokenDecimals);
  const usdValue = parseFloat(formattedAmount) * pricePerToken;

  return usdValue.toFixed(2);
};

export const getBridgeProvider = (
  actions: Array<{ type: string; provider: string }>,
): string | null => {
  const bridgeAction = actions.find(action => action.type === "bridge" || action.type === "rfq");
  return bridgeAction ? bridgeAction.provider : null;
};

export const calculateTotalFeeCost = (feeCosts: Array<{ amountUsd: string }>): number => {
  return feeCosts.reduce((total, fee) => total + parseFloat(fee.amountUsd), 0);
};
