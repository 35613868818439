import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SquidRFQOrder } from "../types";
import { fetchLatestOrders } from "../services/api";
import { notificationStyle, showNotificationStyle } from "../styles/styles";

import { isValidAddress, isValidTransactionHash } from "../utils/validation";

import { OrdersTable } from "./shared/orders-table/OrdersTable";
import { OrdersPageHeader } from "./OrdersPageHeader";
import { LoadOrdersButton } from "./shared/LoadOrdersButton";
import { PageLayout } from "./shared/PageLayout";

const Notification = ({ message, visible }: { visible: boolean; message: string }) => (
  <div
    style={{
      ...notificationStyle,
      ...(visible ? showNotificationStyle : {}),
    }}
  >
    {message}
  </div>
);

const Latest: React.FC = () => {
  const { address } = useParams<{ address: string }>();
  const navigate = useNavigate();

  const [orders, setOrders] = useState<SquidRFQOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [hasMoreOrders, setHasMoreOrders] = useState<boolean>(true);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");

  const [hashInput, setHashInput] = useState(address);

  const orderIdSet = useRef<Set<string>>(new Set());
  const initialLoad = useRef<boolean>(false);

  const loadOrders = async (index: number = 0) => {
    try {
      setLoadingMore(index !== 0);
      const data = await fetchLatestOrders(index, "");

      if (data) {
        const uniqueNewOrders = data.filter(order => !orderIdSet.current.has(order.id));
        uniqueNewOrders.forEach(order => orderIdSet.current.add(order.id));

        if (uniqueNewOrders.length > 0) {
          setOrders(prevOrders => [...prevOrders, ...uniqueNewOrders]);
        }

        setHasMoreOrders(uniqueNewOrders.length >= 25);
      }
    } catch (error) {
      console.error("Error fetching latest orders:", error);
    } finally {
      setLoadingMore(false);
      if (index === 0) setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      loadOrders(0);
      initialLoad.current = true;
    }
  }, []);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
    }
  };

  return (
    <PageLayout>
      <OrdersPageHeader
        hashInput={hashInput}
        setHashInput={setHashInput}
        handleSearchSubmit={handleSearchSubmit}
      />

      <Notification message={notificationMessage} visible={notificationVisible} />

      <OrdersTable isLoading={loading} orders={orders} />

      {!loading && orders.length > 0 && (
        <LoadOrdersButton
          hasMoreOrders={hasMoreOrders}
          isLoading={loadingMore}
          onClick={() => loadOrders(orders.length)}
        />
      )}
    </PageLayout>
  );
};

export default Latest;
