import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
/* keep-import-order */
import "./styles/global.css";
/* keep-import-order */
import "@0xsquid/ui/dist/index.css";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
