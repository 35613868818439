import React, { useCallback, useEffect, useRef, useState } from "react";
import { authenticatorContainerStyle } from "../styles/styles";
import { setIntegratorId } from "../services/session";
import { fetchDashboardData } from "../services/api";
import { BodyText, Button, HeadingText, Input, useUserTheme } from "@0xsquid/ui";
import { Backdrop } from "./icons/Backdrop";
import { SquidLogo } from "./icons/SquidLogo";

interface AuthenticatorProps {
  children: React.ReactNode;
}

const Authenticator: React.FC<AuthenticatorProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [integratorId, setIntegratorIdState] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isDarkMode } = useUserTheme();

  useEffect(() => {
    const storedIntegratorId = process.env.REACT_APP_INTEGRATOR_ID || "";
    if (storedIntegratorId) {
      setIntegratorIdState(storedIntegratorId);
      validateIntegratorId(storedIntegratorId);
    } else {
      setLoading(false);
    }
  }, []);

  const validateIntegratorId = async (id: string) => {
    setLoading(true);
    try {
      await fetchDashboardData();
      setIntegratorId(id);
      setAuthenticated(true);
      setError(null);
    } catch (err) {
      console.error("Error verifying integrator ID:", err);
      setError("Invalid integrator ID. Please try again.");
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const updateAndValidateId = () => {
    setIntegratorId(integratorId);
    validateIntegratorId(integratorId);
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateAndValidateId();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntegratorIdState(event.target.value);
    setError(null);
  };

  const pasteFromClipboard = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    if (text) {
      setIntegratorIdState(text);
      setError(null);
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          ...authenticatorContainerStyle,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h2>Loading...</h2>
      </div>
    );
  }

  return authenticated && !error ? (
    <>{children}</>
  ) : (
    <div className="tw-flex tw-w-full tw-h-full tw-flex-col tw-items-center tw-gap-squid-xxs">
      <div
        style={{
          color: isDarkMode ? "var(--st-color-royal-500)" : "var(--st-color-royal-500)",
          backgroundColor: isDarkMode ? "var(--st-color-grey-900)" : "#F1EDF7",
        }}
        className="tw-pointer-events-none tw-fixed tw-inset-0 tw-hidden tw-max-h-screen tw-scale-110 tw-overflow-hidden mobile-lg:tw-block"
      >
        <Backdrop />
      </div>

      <section className="tw-flex tw-p-squid-m tw-justify-center tw-items-start tw-gap-squid-m tw-flex-1 tw-self-stretch tw-z-0 tw-max-w-[1440px] tw-mx-auto tw-w-full">
        <div className="tw-flex tw-p-squid-xs tw-flex-col tw-justify-center tw-items-center tw-gap-squid-xs tw-flex-1 tw-self-stretch">
          <div className="tw-flex tw-h-[264px] tw-flex-col tw-justify-center tw-items-center tw-gap-squid-xl">
            <div
              className="tw-flex-shrink-0"
              style={{
                filter:
                  "drop-shadow(0px 10px 100px rgba(0, 0, 0, 0.33)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.20))",
              }}
            >
              <SquidLogo size={160} />
            </div>

            <HeadingText size="large">Coralscan</HeadingText>
          </div>
        </div>

        <form
          onSubmit={handleLogin}
          className="tw-shadow-elevation-dark-3 tw-flex tw-pt-squid-xs tw-px-[100px] tw-pb-squid-xl tw-flex-col tw-justify-center tw-items-center tw-gap-squid-m tw-flex-1 tw-self-stretch tw-rounded-squid-m tw-border tw-border-material-light-thin tw-bg-grey-900 tw-max-w-[690px]"
        >
          <header className="tw-flex tw-py-squid-xs tw-px-0 tw-flex-col tw-items-center tw-gap-squid-m">
            <HeadingText size="medium">Welcome</HeadingText>

            <BodyText size="small">Enter your Squid ID to continue</BodyText>
          </header>

          <Input
            inputRef={inputRef}
            type="text"
            placeholder="Integrator ID"
            value={integratorId}
            onChange={handleChange}
            actionButtonProps={{
              label: "Paste",
              onClick: pasteFromClipboard,
            }}
          />

          <Button
            // TODO: update squid-ui to allow passing type="submit" to the button
            // so it submits the form when clicked
            // for now we need to use onClick
            onClick={updateAndValidateId}
            size="lg"
            variant="primary"
            className="tw-text-body-medium"
          >
            Sign In
          </Button>

          {error && <p className="tw-text-status-negative">{error}</p>}
        </form>
      </section>
    </div>
  );
};

export default Authenticator;
