import { BodyText, Checkmark1Icon, ClockOutlineIcon, cn, ReorderIcon } from "@0xsquid/ui";
import React from "react";
import { useMemo } from "react";
import { SquidRFQOrder } from "src/types";
import { currentTime } from "src/utils/constants";
import { formatDateTime, formatElapsedTime, formatTxHash } from "src/utils/formatting";
import { OrderRowCell } from "./OrderRowCell";
import { Link } from "react-router-dom";
import { TransferDetails } from "../TransferDetails";

enum OrderStatus {
  FILLED = "Filled",
  REFUNDED = "Refunded",
  FILLING = "Filling",
}

const orderStatusToIconMap: Record<OrderStatus, React.ReactNode> = {
  [ OrderStatus.FILLED ]: <Checkmark1Icon size="16px" />,
  [ OrderStatus.REFUNDED ]: <ReorderIcon size="16px" />,
  [ OrderStatus.FILLING ]: <ClockOutlineIcon size="16px" />,
};

const orderStatusToClassMap: Record<OrderStatus, string> = {
  [ OrderStatus.FILLED ]: "tw-text-status-positive",
  [ OrderStatus.REFUNDED ]: "tw-text-status-partial",
  [ OrderStatus.FILLING ]: "tw-text-grey-300",
};

interface OrderRowProps {
  order: SquidRFQOrder;
}

export function OrderRow({ order }: OrderRowProps) {
  const { elapsedTime, status } = useMemo<{
    status: OrderStatus;
    elapsedTime: string | null;
  }>(() => {
    let elapsedTime = "success";

    if (order.orderFilledTxHash) {
      elapsedTime = formatElapsedTime(
        order.orderFilledBlockTimestamp - order.orderCreatedBlockTimestamp,
      );

      return {
        elapsedTime,
        status: OrderStatus.FILLED,
      };
    } else if (order.orderRefundedTxHash) {
      return {
        elapsedTime: null,
        status: OrderStatus.REFUNDED,
      };
    } else {
      elapsedTime = formatElapsedTime(currentTime - order.orderCreatedBlockTimestamp);
      return {
        elapsedTime,
        status: OrderStatus.FILLING,
      };
    }
  }, [ order ]);

  return (
    <tr className="table-padding-x tw-h-squid-xxl tw-p-squid-m">
      <OrderRowCell>
        <BodyText size="small" className="tw-text-royal-500 tw-font-mono">
          <Link to={`/tx/${order.id}`}>{formatTxHash(order.id)}</Link>
        </BodyText>
      </OrderRowCell>

      <OrderRowCell>
        <TransferDetails
          amount={order.fromAmount}
          chain={order.fromChain}
          tokenSymbol={order.fromTokenSymbol}
          tokenDecimals={order.fromTokenDecimals}
        />
      </OrderRowCell>

      <OrderRowCell>
        <TransferDetails
          amount={order.fillAmount}
          chain={order.toChain}
          tokenSymbol={order.toTokenSymbol}
          tokenDecimals={order.toTokenDecimals}
        />
      </OrderRowCell>

      <OrderRowCell>
        <div className="tw-flex tw-items-center tw-gap-squid-xs tw-flex-1 tw-self-stretch">
          <div
            className={cn(
              "tw-flex tw-items-center tw-justify-center tw-gap-squid-xxs tw-rounded-squid-m tw-h-squid-m",
              orderStatusToClassMap[ status ],
            )}
          >
            {orderStatusToIconMap[ status ]}

            <BodyText size="small">{status}</BodyText>
          </div>

          {elapsedTime != null && (
            <div className="tw-flex tw-h-squid-m tw-justify-center tw-items-center tw-gap-squid-xxs tw-text-grey-500">
              <ClockOutlineIcon size="16px" />
              <BodyText size="small">{elapsedTime}</BodyText>
            </div>
          )}
        </div>
      </OrderRowCell>

      <OrderRowCell>{formatDateTime(order.orderCreatedBlockTimestamp)}</OrderRowCell>
    </tr>
  );
}
