import React, { useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SquidRFQOrder } from "../types";
import { fetchLatestOrders } from "../services/api";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import { OrdersPageHeader } from "./OrdersPageHeader";
import { OrdersTable } from "./shared/orders-table/OrdersTable";
import { Notification } from "./shared/Notification";
import { LoadOrdersButton } from "./shared/LoadOrdersButton";
import { PageLayout } from "./shared/PageLayout";

const LatestOrders: React.FC = () => {
  const { address } = useParams<{ address: string }>();
  const navigate = useNavigate();

  const [orders, setOrders] = useState<SquidRFQOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [hasMoreOrders, setHasMoreOrders] = useState<boolean>(true);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");

  const [hashInput, setHashInput] = useState(address);

  const loadOnceRef = useRef<{ address?: string }>({});
  const orderIdSet = useRef<Set<string>>(new Set());

  const loadOrders = async (index: number = 0) => {
    try {
      setLoadingMore(index !== 0);
      if (address) {
        const data = await fetchLatestOrders(index, hashInput);

        if (data) {
          const uniqueNewOrders = data.filter(order => !orderIdSet.current.has(order.id));
          uniqueNewOrders.forEach(order => orderIdSet.current.add(order.id));

          if (uniqueNewOrders.length > 0) {
            setOrders(prevOrders => [...prevOrders, ...uniqueNewOrders]);
          }

          setHasMoreOrders(uniqueNewOrders.length >= 25);
        }
      }
    } catch (error) {
      console.error("Error fetching latest orders:", error);
    } finally {
      setLoadingMore(false);
      if (index === 0) setLoading(false);
    }
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      setOrders([]);
      setHasMoreOrders(true);
      orderIdSet.current.clear();
      loadOrders(0);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
    }
  };

  const initialLoad = () => {
    loadOnceRef.current.address = address;
    setOrders([]);
    setHasMoreOrders(true);
    orderIdSet.current.clear();
    loadOrders(0);
  };

  if (address && address !== loadOnceRef.current.address) {
    initialLoad();
  }

  return (
    <PageLayout>
      <OrdersPageHeader
        handleSearchSubmit={handleSearchSubmit}
        hashInput={hashInput}
        setHashInput={setHashInput}
      />

      <Notification message={notificationMessage} visible={notificationVisible} />

      <OrdersTable isLoading={loading} orders={orders} />

      {!loading && orders.length > 0 && (
        <LoadOrdersButton
          hasMoreOrders={hasMoreOrders}
          isLoading={loadingMore}
          onClick={() => loadOrders(orders.length)}
        />
      )}
    </PageLayout>
  );
};

export default LatestOrders;
