import React from "react";
import { Spinner } from "../../TransactionDetails";
import { BodyText, CaptionText } from "@0xsquid/ui";
import { SquidRFQOrder } from "src/types";
import { OrderRow } from "./OrderRow";

interface OrdersTableProps {
  isLoading: boolean;
  orders: SquidRFQOrder[];
}

export function OrdersTable({ isLoading, orders }: OrdersTableProps) {
  if (isLoading) {
    return (
      <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
        <Spinner />
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="tw-h-full tw-flex tw-items-center tw-justify-center">
        <BodyText className="tw-text-center" size="small">
          No Orders found
        </BodyText>
      </div>
    );
  }

  return (
    <div className="tw-w-full tw-overflow-auto">
      <table className="tw-w-[1400px] tw-pt-squid-xs tw-table-auto">
        <thead className="tw-w-full tw-overflow-auto">
          <tr className="table-padding-x tw-h-squid-xl tw-py-0 tw-px-squid-m tw-border-b tw-border-b-material-light-thin">
            {["Order Hash", "Source", "Destination", "Status", "Created At"].map(title => (
              <th className="tw-text-grey-500 tw-text-start">
                <CaptionText>{title}</CaptionText>
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="tw-w-full tw-overflow-auto">
          {orders.map(order => (
            <OrderRow key={order.id} order={order} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
